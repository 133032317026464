export default class AppEvent {
    constructor(){
        this.events = [];
    }
    
    add = (event) => {
        this.events.push(event);
    };

    remove = (event) => {
        var index = this.events.indexOf(event);
        if (index > -1) {
            this.events.splice(index, 1);
        }
    };

    execute = function() {
        for (let event of this.events) {
            event.apply(this, arguments);
        }
    };

    clear = () => {
        this.events.splice(0, this.events.length);
    };
}