import React from "react";
import { FiSearch } from "react-icons/fi"; 
import AppUtils from "../../core/app.utils";
import SHListBase from '../core/base/sh.list.base';

export default class LightboxList extends SHListBase {
    constructor(props){
      super(props)
      
      this.searchText = this.useState('');
    }

    getUrl = ({ startIndex, stopIndex }) => {
        let load = this.props.fieldDef.source.bind(this);
        return load({ startIndex, stopIndex });  
    }

    renderContent = ({style, data}) => {
        let select = this.props.fieldDef.renderContent.bind(this);
        return select({style, data});
    }

    back = () => {
      this.props.showLightboxList.value = false;
    }

    onChange = (event) => {
      this.searchText.value = event.target.value;
    }

    selectItem = (data) => {
      let row = { value: AppUtils.getPropByPath(data, this.props.fieldDef.entityId), label: AppUtils.getPropByPath(data, this.props.fieldDef.textField), row: data };  
      this.props.options.clearInstance();
      this.props.options.value.push(row);
      this.props.value.value = row.value;
      this.back();
    }

    getSearchCriteria = (searchText) => {
      if(AppUtils.isNullOrEmpty(searchText)){
        return '';
      }
      return '&$filter=' + this.props.fieldDef.searchFilter(searchText);
    }

    search = async () => {
      await this.fillData({ startIndex: 0, stopIndex: this.itemsPerPage });
    }

    render() {
      return (
        <div>
          <div className="input-group mb-3">
            <button className="btn btn-success" onClick={ this.back }>Back</button>
            <input type="text" value={ this.searchText.value } onChange={ this.onChange } className="form-control" />
            <div className="input-group-prepend">
                <span className="input-group-text"><FiSearch onClick={ this.search } size={26} /></span>
            </div>
          </div>
          { this.renderList() }
        </div>
      );
    }
}