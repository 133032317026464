import React from "react";
import Menu from '../menu/menu.component';
import { Link } from "react-router-dom";
import SHFormBase from '../core/base/sh.form.base';
import SongCutter from "../song.cutter/song.cutter.component";
import AppConfig from "../../configurations/app.config";
import AppFetch from "../../core/app.fetch";
import AppUtils from "../../core/app.utils";
import Button from "react-bootstrap/Button";
import { toast } from 'react-toastify';
import DateCreatedLabel from '../core/date.created.label.component';
import './song.cut.template.component.css';

export default class SongCutTemplate extends SHFormBase {
    constructor(props){
        super(props)
        this.state = {}
        this.cuts = this.useStateArray();

        this.fieldDefs = {
            Title: {
                controlType: 'Textbox',
                label: 'Cut Version'
            },
            SongName: {
                controlType: 'Label',
                label: 'Song Name',
                showOnNew: false
            },
            SongId: {
                label: 'Song',
                controlType: 'LightboxList',
                entityType: 'Song',
                textField: 'Title',
                entityId: 'Id',
                searchFilter: function(searchText) { return "contains(Title, '" + searchText + "') or contains(Author, '" + searchText + "')" },
                source: function({ startIndex, stopIndex }) {  return AppConfig.dataServiceUrl + '/Admin/Song?$expand=CreatedBy' + this.getSearchCriteria(this.searchText.value) + this.getSkipAndTakeUrl({ startIndex, stopIndex }); },
                renderContent: function({style, data}){ return ( 
                    <div key={data.Id} className="card" style={style} onClick={() => { this.selectItem(data) }}>
                    <div className="row no-gutters">
                      <div className="col-auto">
                        <img src={ '/images/noperson.png' } className="img-fluid" alt="" width="64" />
                      </div>
                      <div className="col">
                        <div className="card-block px-2">
                            <h4 className="card-title"><button className="btn btn-link padding-left0">{data.Author}, {data.Title}</button></h4>
                            <DateCreatedLabel createdDate={data.CreatedDate} firstName={data.CreatedBy.FirstName} lastName={data.CreatedBy.LastName} />
                        </div>
                      </div>
                    </div>
                  </div>) }
            },
            SongCut: {
                label: 'Song cut',
                controlType: 'Combobox',
                sourceType: 'Custom',
                sourceUrl: function(model, fieldDef) { return AppConfig.dataServiceUrl + model.getArea() + '/SongCut?$filter=SongCutTemplateId eq ' + model.id.value },
                sourceMap: (x) => Object.assign({}, x),
                textField: 'TimeCut',
                entityId: 'Id',
                showOnEdit: false,
                showOnNew: false
            }
        }
        this.area = 'Admin';
        this.entityType = 'SongCutTemplate';

        if(!this.isNew()){
            this.fieldDefs.SongId.controlType = 'Textbox';
            this.fieldDefs.SongId.showOnEdit = false;
        }

        this.initFields(this);
        this.initValidationState(this);
    }

    getUrl = () => {
        return AppConfig.dataServiceUrl + this.getArea() + '/' + this.entityType + '(' + this.id.value + ')?$expand=Song($select=Title)';
    }

    componentDidMount(...args) {
        this.fieldDefs.SongId.readOnly = !this.isNew();
        this.events.postSave.add(function(model, isNew, request){
            if(request.status == null){
                let action = AppUtils.getActionFromPath(model.props.match.path);
                model.props.history.push('/' + action + 'list/' + (model.props.match.params.scroll || ''));
            }
        });

        this.events.postLoad.add(function(model, result) {
            model.poco.SongName = result.Song.Title;
        })

        super.componentDidMount.apply(this, args);
    }

    save = async () => {
        let isValid = await this.handleValidation(null);
        if(!isValid){
            return;
        }
        let delta = this.getDelta();
        let isNew = this.isNew();
        let cuts = this.cuts.value;

        let model = { 
            Id: this.id.value,
            Title: delta.Title || null,
            Description: delta.Description || null,
            SongId: delta.SongId || null,
            Rows: cuts
        }

        let request = await AppFetch.post(AppConfig.dataServiceUrl + '/' + this.entityType + '/BulkCutSave', model);

        this.events.postSave.execute(this, isNew, request);

        if(request.status == 400){
            for(let error of request.result.errors){
                toast.warning(error.FieldName + ' - ' + error.Text);
            }
            return;
        }

        toast.success('Successfully saved.');
    }

    getUrl = () => {
        return AppConfig.dataServiceUrl + this.getArea() + '/' + this.entityType + '(' + this.id.value + ')?$expand=Song';
    }

    render() {
      return (
        <div>
            { this.contentDeleteDialogRender() }
            <div className={ this.showLightboxList.value ? 'hide-panel' : '' }>
                <Menu title="Song Cutter" />
                <div className="user-container">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <Link className="btn btn-success" to={'/songcuttemplatelist/' + (this.props.match.params.scroll || '') + '/' + (this.props.match.params.search || '')}>Back</Link>
                            { this.isNew() ? "" : <Button className="btn btn-danger" onClick={ this.showDeleteDialog }>delete</Button> }
                            { this.fieldDefsArray.map(x => { return this.renderField(x) })}
                            
                            <button className="btn btn-success" onClick={ this.save }>Save</button>
                            { this.data.SongId.value > 0 ? <SongCutter songId={ this.data.SongId } dataCuts={ this.dataOptions.SongCut } cuts={ this.cuts } /> : '' }
                        </div>
                    </div>
                </div>
            </div>
        </div>
      );
    }
}