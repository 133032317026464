import React from "react";
import Menu from '../menu/menu.component';
import SHListBase from '../core/base/sh.list.base';
import AppConfig from "../../configurations/app.config";
import DateCreatedLabel from '../core/date.created.label.component';
import AppUtils from "../../core/app.utils";
 

export default class SongList extends SHListBase {
    constructor(props){
      super(props)
      this.area = 'Admin';
      this.entityType = 'Song';
    }

    getUrl = ({ startIndex, stopIndex }) => {
      return AppConfig.dataServiceUrl + '/' + this.area +'/' + this.entityType + '?&$expand=CreatedBy($select=FirstName,LastName)' + this.getSearchCriteria() + this.getSkipAndTakeUrl({ startIndex, stopIndex });
    }

    renderContent = ({style, data}) => {
      return <div key={data.Id} className="card" style={style} onClick={ (id) => { this.goLink(data.Id, 'song') }}>
        <div className="row no-gutters">
          <div className="col-auto">
            <img src={ '/images/noperson.png' } className="img-fluid" alt="" width="64" />
          </div>
          <div className="col">
            <div className="card-block px-2">
                <h4 className="card-title"><button className="btn btn-link padding-left0">{data.Author}, {data.Title}</button></h4>
                <DateCreatedLabel createdDate={data.CreatedDate} firstName={data.CreatedBy.FirstName} lastName={data.CreatedBy.LastName} />
            </div>
          </div>
        </div>
      </div>
    }

    getSearchCriteria = () => {
      let searchText = this.searchText.value;
      if(AppUtils.isNullOrEmpty(searchText)){
        return '';
      }
      return "&$filter=contains(Title, '" + searchText + "') or contains(Author, '" + searchText + "')";
    }

    render() {
      return (
        <div>
          <Menu title="Upload MP3" searchText={this.searchText} showSearch={true} showNew={true} />
          { this.renderList() }
        </div>
      );
    }
}