import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import './card.list.component.css';

class CardList extends Component {
    constructor(props){
        super(props)
    }

    render() {
      return (
          <>
          { this.props.isLoading ? <div className="spinner-border text-danger" role="status"></div> : "" }
          { (!this.props.isLoading && this.props.items.length) ? this.props.items.map(x => { return (
            <div key={x.Id} className="card">
                <div className="row no-gutters">
                    { this.props.contentCardRender(x) }
                </div>
            </div>
          ) }) : !this.props.isLoading ? ( 
          <div className="card">
              <div className="row no-gutters">
              <Alert variant="warning">No items found.</Alert>
              </div>
          </div>) : "" }
        </>
      );
    }
}

export default withRouter(CardList);