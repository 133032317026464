import AppUtils from "./app.utils";

export class SHStateObservable {
    constructor(value, state, setState){
        this._subscribers = []
        this._value = value;
        this._id = AppUtils.getGuid();
        this._setState = setState;
        this._state = state;

        state['cenState' + this._id] = this._value;
    }

    get value() {
        return this._value;
    }

    set value(v) {
        this._value = v;
        this.valueHasMutated();
    }

    get isObservable() {
        return true;
    } 

    asyncValue = (v, delta) => {
        this._value = v;
        if(delta.state == null){
            delta.state = {};
        }

        if(delta.subRefs == null){
            delta.subRefs = {};
        }

        delta.state['cenState' + this._id] = v;
        delta.subRefs['cenState' + this._id] = this;
    }

    valueHasMutated = () => {
        console.log('set state regular observable');
        this._setState((state) => { 
            let t = {};
            t['cenState' + this._id] = this._value ;
            return t 
        }, () => {
            this.fire();
        });
    }

    subscribe = (fn, dispose) => {
        var self = this;
        fn.dispose = function() { self.unsubscribe(this); };
        this._subscribers.push(fn);
        if(dispose){
            dispose.push(fn);
        }
    }

    unsubscribe = (fn) => {
        this._subscribers = this._subscribers.filter(x => x != fn);
    }

    fire = () => {
        this._subscribers.forEach(x=> x(this._value));
    }
}

export class SHStateObservableArray extends SHStateObservable {
    push = (v) => {
        this.value.push(v);
        this.valueHasMutated();
    }

    splice = (start, num) => {
        this.value.splice(start, num);
        this.valueHasMutated();
    }

    clearInstance = () => {
        this.value.splice(0, this.value.length);
    }

    pushInstance = (v) => {
        this.value.push(v);
    }

    pushAll = (array) => {
        for(let v of array){
            this.pushInstance(v);
        }
        this.valueHasMutated();
    }
}

class SHState {
    constructor(){
    }

    observable = (value, state, setState) => {
        return new SHStateObservable(value, state, setState);
    }

    observableArray = (value, state, setState) => {
        return new SHStateObservableArray(value, state, setState);
    }

    asyncObservableComplete = (asyncState, setState) => {
        setState((state) => {
            let newState = AppUtils.deepClone(asyncState.state);
            return newState
        }, () => {
            let storage = asyncState.subRefs;
            for(let prop in storage){
                storage[prop].fire();
            }
        });
    }
}

export default new SHState()