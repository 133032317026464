import AppFetch from "../core/app.fetch";
import AppConfig from "./app.config";

class EntityMetadata {
    constructor(){
        this.metadata = null;
    }

    init = async () => {
        let result = await AppFetch.get(AppConfig.dataServiceUrl + '/application/metadata');
        this.metadata  = result
    }

    getPrimaryKey = (modelName) => {
        let metadata = this.metadata[modelName];
        return metadata ? metadata.PrimaryKey : null;
    } 

    getValidationMetadata = (modelName) => {
        let metadata = this.metadata[modelName];
        if(metadata){
            return  { 
                entityType: metadata.EntityType || metadata.Name,
                uniqueness: metadata.Uniqueness,
                fields: metadata.Validations
            };
        }
    }

    validate = (value, modelName) => {
        let metadata = this.Metadata[modelName];
    }
}

export default new EntityMetadata();