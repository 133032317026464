import React from "react";
import Menu from '../menu/menu.component';
import { Link } from "react-router-dom";
import SHFormBase from '../core/base/sh.form.base';
import AppConfig from '../../configurations/app.config';
import AppFetch from "../../core/app.fetch";
import DateCreatedLabel from '../core/date.created.label.component';
import AppUtils from "../../core/app.utils";

export default class Video extends SHFormBase {
    constructor(props){
        super(props)
        this.state = {}

        this.fieldDefs = {
            Status: {
                controlType: 'Combobox',
                sourceType: 'Custom',
                enum: 'CommentStatus',
                label: 'Status'
            },
        }

        this.area = 'Admin';
        this.entityType = 'Video';
        this.initFields(this);
        this.initValidationState(this);

        this.commentCount = this.useState(0)
        this.expandFields.Dance = {
            SongCutTemplate: {
                Song: {}
            },
            DanceCut: {
                DanceBy: {}
            }
        };
        this.expandFields.CreatedBy = {}
        this.currentData = this.useState(null)
        this.currentCut = this.useState(null);
    }

    getVideoUrl = () => {
        return AppConfig.dataServiceUrl + '/Video/Stream/' + this.id.value;
    }

    componentDidMount(...args) {
        super.componentDidMount.apply(this, args);
        this.loadCommentCounts();
        this.events.postLoad.add(function(model, result) {
            model.currentData.value = result;
        })
    }

    loadCommentCounts = async () => {
        let request = await AppFetch.get(AppConfig.dataServiceUrl + this.getArea() + '/Comment?$count=true&$top=0&$filter=VideoId eq ' + this.id.value);
        this.commentCount.value = request.count;
    }

    showComments = () => {
        var id = this.id.value;
        let field = {
            entityType: 'Comment',
            searchFilter: function(searchText) { return "contains(Text, '" + searchText + "')" },
            source: function({ startIndex, stopIndex }) { 
                let fil = '&$filter=VideoId eq ' + id;
                if(!AppUtils.isNullOrEmpty(this.searchText.value)){
                    fil += ' and (' + this.props.fieldDef.searchFilter(this.searchText.value) + ')';
                }

                return AppConfig.dataServiceUrl + '/Admin/Comment?$select=Text,CreatedDate,Id&$expand=CreatedBy($select=FirstName,LastName),Video($select=Id;$expand=Dance($select=Title))' + fil + this.getSkipAndTakeUrl({ startIndex, stopIndex }); 
            },
            renderContent: function({style, data}){ return ( <div key={data.Id} className="card" style={style}>
                <div className="row no-gutters">
                <div className="col-auto">
                    <img src={ '/images/noperson.png' } className="img-fluid" alt="" width="64" />
                </div>
                <div className="col">
                    <div className="card-block px-2">
                        <h4 className="card-title">{data.Video.Dance.Title}</h4>
                        <p className="card-text">{data.Text}</p>
                        <DateCreatedLabel createdDate={data.CreatedDate} firstName={data.CreatedBy.FirstName} lastName={data.CreatedBy.LastName} />
                    </div>
                </div>
                </div>
            </div>)
            }
        }

        this.setShowLightboxList(field);
    }

    videoOnTimeUpdate = (event) => {
        let current = event.target.currentTime;
        let cuts = this.poco.Dance.DanceCut.sort((a, b) => a.TimeCut-b.TimeCut);

        let cut = cuts.find(x => x.TimeCut > current);
        

        if(this.currentCut.value != cut){
            this.currentCut.value = cut;
        }
    }

    render() {
      return (
          <div>
              { this.contentDeleteDialogRender() }
              <div className={ this.showLightboxList.value ? 'hide-panel' : '' }>
                <Menu title="Finished Videos" />
                <div className="user-container">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <Link className="btn btn-success" to={'/videolist/'+ (this.props.match.params.scroll || '') + '/' + (this.props.match.params.search || '')}>Back</Link>
                            { this.fieldDefsArray.map(x => { return this.renderField(x) })}
                            <div className="input-group mb-3">
                                <span className="form-control">{ (this.currentData.value ) ? this.currentData.value.Dance.SongCutTemplate.Song.Author : ''}</span>
                            </div>
                            <div className="input-group mb-3">
                                <span className="form-control">{ (this.currentData.value ) ? this.currentData.value.Dance.SongCutTemplate.Song.Title : ''}</span>
                            </div>

                            { !this.isNew() ? (
                            <div className="input-group mb-3">
                                <video width="250" height="300" onTimeUpdate={ this.videoOnTimeUpdate } controls>
                                    <source src={ this.getVideoUrl() } type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            ) : "" }
                            { (this.currentCut.value ) ? <div> <img className="avatar-image avatar-image-small" src={ this.currentCut.value.DanceBy.Photo } /> <div>{this.currentCut.value.DanceBy.FirstName + ' ' + this.currentCut.value.DanceBy.LastName}</div></div> : '' }

                            { (this.currentData.value ) ? <img className="avatar-image avatar-image-small" src={ this.currentData.value.CreatedBy.Photo } /> : ''}
                            <div className="input-group mb-3">
                                <span className="form-control">{ (this.currentData.value ) ? this.currentData.value.CreatedBy.FirstName : ''}</span>
                            </div>
                            <div className="input-group mb-3">
                                <span className="form-control">{ (this.currentData.value ) ? this.currentData.value.Dance.Title : ''}</span>
                            </div>
                            <button className="btn btn-success" onClick={ this.showComments }>Show Comments ({this.commentCount.value})</button>
                            <button className="btn btn-success" onClick={ this.save }>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      );
    }
}