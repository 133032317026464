import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";


class ModalDialog extends Component {
    constructor(props){
        super(props)
    }

    render() {
      return (
        <>
            <Modal show={this.props.show} onHide={this.props.close}>
                <Modal.Header closeButton>
                <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{ this.props.message }</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={this.props.close}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={this.props.ok}>
                    Ok
                </Button>
                </Modal.Footer>
            </Modal>
        </>  
      );
    }
}

export default withRouter(ModalDialog);