import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class FieldText extends Component {
    constructor(props){
        super(props)
        this.state = {}
    }

    render() {
      return (
        <>
            <input type={ this.props.type || "text" }  { ...this.props.autoComplete && {autoComplete : this.props.autoComplete} } value={ this.props.value } onChange={ this.props.onChange } onBlur={this.props.onBlur} className="form-control" aria-label={ this.props.name } aria-describedby="basic-addon1" />
        </>  
      );
    }
}

export default withRouter(FieldText);