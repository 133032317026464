import React from "react";
import { Nav, NavDropdown, Navbar, Container, DropdownButton  } from "react-bootstrap";
import AppUser from "../../core/app.user";
import { withRouter } from "react-router-dom";
import SHBaseComponent from "../core/base/sh.base.component";
import SearchTexbox from "./search.textbox.component.js";
import './menu.component.css';

class Menu extends SHBaseComponent {
    constructor(props){
        super(props)
        this.state = {}
    }

    handleSelect = (eventKey) => { 
        switch(eventKey) {
            case 'logout':
                AppUser.logout();
                this.props.history.push('/login');
                break;
            case 'new':
                let scroll = document.getElementsByClassName('ReactVirtualized__Grid ReactVirtualized__List')[0].scrollTop;
                let current = this.props.match.path.split('/')[1].replace('list', '');
                this.props.history.push('/' + current + '/-1/' + scroll);
                break;
            default:
                this.props.history.push('/' + eventKey + 'list');
                break;
        }
    }

    render() {
      return (
       <>
            <Navbar bg="dark" variant="dark" fixed="top" sticky="top">
                <Container>
                <Navbar.Brand href="#home">{ this.props.title}</Navbar.Brand>
                { ( this.props.showSearch === true ) ? <SearchTexbox searchText={this.props.searchText} /> : ''}
                
                <Nav variant="pills" activeKey="1" onSelect={this.handleSelect}>
                    <DropdownButton  id="dropdown-basic-button" align="end" title="Actions">
                        <NavDropdown.Item eventKey="user">Users</NavDropdown.Item>
                        <NavDropdown.Item eventKey="song">Upload MP3</NavDropdown.Item>
                        <NavDropdown.Item eventKey="songcuttemplate">Cutter</NavDropdown.Item>
                        <NavDropdown.Item eventKey="dance">Record clips</NavDropdown.Item>
                        <NavDropdown.Item eventKey="video">Finished Videos</NavDropdown.Item>
                        <NavDropdown.Item eventKey="comment">Comments</NavDropdown.Item>
                        { this.props.showNew === true ? <><NavDropdown.Divider /><NavDropdown.Item eventKey="new">New</NavDropdown.Item></> : '' }
                        <NavDropdown.Divider />
                        <NavDropdown.Item eventKey="logout">Logout</NavDropdown.Item>
                    </DropdownButton>
                    </Nav>
                </Container>
            </Navbar>
       </>
      );
    }
}

export default withRouter(Menu);