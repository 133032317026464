import { Component } from "react";
import SHState from "../../../core/sh.state.observable";

export default class SHBaseComponent extends Component {
    constructor(props){
        super(props);
        this.disposable = [];
    }

    useState = (value) => {
        return SHState.observable(value, this.state, this.setState.bind(this));
    }

    useAsyncStateComplete = (asyncState) => {
        SHState.asyncObservableComplete(asyncState, this.setState.bind(this));
    }

    useStateArray = (value) => {
        return SHState.observableArray(value || [], this.state, this.setState.bind(this));
    }

    componentWillUnmount() {
        for(let disp of this.disposable){
            disp.dispose();
        }
    }
}