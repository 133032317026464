import AppConfig from '../../../configurations/app.config';
import SHBaseComponent from "./sh.base.component";
import SHUtils from "../../../core/sh.observable.utils";

export default class SHSongPlayerBase extends SHBaseComponent {
    constructor(props){
        super(props);
        this.state = {}
        this.totalHeight = this.useState(0);
        this.currentHeight = this.useState(0);
        this.currentTime = this.useState(null);
        this.isPlaying = this.useState(false);
        this.cuts = SHUtils.isObservable(this.props.cuts) ? this.props.cuts : this.useStateArray();
        this.secondHeight = 20;
        this.audioElement = null;
        this.dataCuts = this.props.dataCuts;
    }

    setTotalHeight = (totalSeconds) => {
        let totalHeight = totalSeconds * this.secondHeight;
        
        this.totalHeight.value = totalHeight;
      }
  
      getSongUrl = () => {
        return AppConfig.dataServiceUrl + '/Song/Stream/' + this.props.songId.value;
      }
  
      audioLoaded = (event) => {
        this.setTotalHeight(event.target.duration);
        this.audioElement = event.target;
        this.ensurecurrentLineVisible();
      }
  
      audioOnTimeUpdate = (event) => {
        let currentHeight = this.secondHeight * event.target.currentTime;
  
        this.currentHeight.value = currentHeight;
        this.currentTime.value = event.target.currentTime;
  
        this.ensurecurrentLineVisible(); 

        if(this.props.hasOwnProperty('currentDanceCutId')) {
          this.setCurrentDanceCutId(event.target.currentTime);
        }
      }

      setCurrentDanceCutId = (currentTime) => {
        let cuts = this.cuts.value;
        for(let i = 0; i < cuts.length; i++){
          let c = cuts[i];
          if(i == 0 && currentTime < c.timeCut){
            this.props.currentDanceCutId.value = c.id;
          }

          if(i > 0 &&  currentTime > cuts[i-1].timeCut && currentTime < c.timeCut){
            this.props.currentDanceCutId.value = c.id; 
          }
        }
      }
  
      play = () => {
        if(this.isPlaying.value) { 
          this.audioElement.pause();
        }else{
          this.audioElement.play();
        }
  
        this.isPlaying.value = !this.isPlaying.value;
      }
  
      getCurrentPos = (currentTime) => {
        return this.secondHeight * currentTime;
      }

      calculateDeltaValue = (data) => {
        let arr = this.cuts.value;
        
        let currentIdx = arr.findIndex((p) => p == data);
        
        if(currentIdx == 0){
          return data.timeCut;
        }
        
        let prev = arr[currentIdx - 1];
        return (data.timeCut - prev.timeCut); 
      }

      calculateDelta = (data) => {
          return this.calculateDeltaValue(data).toFixed(2) + 's';
      }
  
      getCurrentTime = () => {
        let totalSeconds = this.currentTime.value;
  
        if(totalSeconds == null){
          return '';
        }
  
        let minutes = Math.floor(totalSeconds / 60);
        let seconds = Math.floor(totalSeconds % 60);
        let miliseconds = totalSeconds - Math.floor(totalSeconds)
  
        return ("0" + minutes).slice(-2)  + ':' + ("0" + seconds).slice(-2) + ':' + ("0" + miliseconds).slice(-2);
      }

      fillCuts = (cuts) => {
        cuts.sort((a, b) => a.TimeCut-b.TimeCut);
        this.cuts.clearInstance();
        for(let i = 0; i < cuts.length; i++){
          let cut = cuts[i];
          this.cuts.pushInstance({ position: this.getCurrentPos(cut.TimeCut), timeCut: cut.TimeCut, idx: i, id: cut.Id, status: cut.Status });
        }
        this.cuts.valueHasMutated();
      }
  
      componentDidMount(...args) {
        this.fillCuts(this.dataCuts.value);
  
        this.props.dataCuts.subscribe((newValue) => {
          this.fillCuts(newValue);
        });
  
        this.props.songId.subscribe((newValue) => { 
          if(this.audioElement){
            this.audioElement.load();
          }
        }, this.disposable);  
      }

      ensurecurrentLineVisible = () => {
        let cont = document.getElementsByClassName('cutter-strip-container')[0];
        let div = document.getElementsByClassName('cutter-current-position')[0];
        
        let topPos = cont.offsetTop + this.currentHeight.value; 
        
        if(window.innerHeight - 100 < div.getBoundingClientRect().top){
          window.scrollTo(0, topPos - 200);
        }
      }

      handleStopCurrent = (event, dragElement) => {
        let totalSeconds = dragElement.y / this.secondHeight;
        this.currentHeight.value = dragElement.y;
        this.currentTime.value = totalSeconds;
  
        this.audioElement.currentTime = totalSeconds;
      }
}