import React from "react";
import AppConfig from "../../configurations/app.config";
import SHBaseComponent from "../core/base/sh.base.component";
import './dance.preview.component.css';

export default class DancePreview extends SHBaseComponent {
    constructor(props){
        super(props)
        this.state = {}
    }

    render() {
        return (
            <div className="sticky-top">
                <div className="dance-preview-container">
                {this.props.dataCuts.value.map(x => { return (<div key={x.Id}>
                    { (this.props.danceCutId.value == x.Id && x.Status == 1) ? <video width="200px" autoPlay={true}><source src={ AppConfig.dataServiceUrl + '/Dance/StreamDanceCut/' + x.Id} type="video/webm" /></video> : ''}
                    </div>) })}
                </div>
            </div>
        );
    }
}