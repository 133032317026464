import React from "react";
import SHFormBase from '../core/base/sh.form.base';
import AppConfig from '../../configurations/app.config';
import Menu from '../menu/menu.component';
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Dropzone from 'react-dropzone';
import AppUtils from "../../core/app.utils";
import axios from "axios";
import './song.component.css';

export default class Song extends SHFormBase {
    constructor(props){
        super(props)
        this.state = {}

        this.files = this.useStateArray();

        this.fieldDefs = {
            Author: {
                controlType: 'Textbox',
                label: 'Author'
            },
            Title: {
                controlType: 'Textbox',
                label: 'Song Name'
            },
            Filename: {
                controlType: 'Textbox',
                showOnEdit: false,
                showOnNew: false
            }
        }

        this.area = 'Admin';
        this.entityType = 'Song';
        this.initFields(this);
        this.initValidationState(this);
    }

    componentDidMount(...args) {
        super.componentDidMount.apply(this, args);
        
        this.events.postSave.add(function(model, isNew, request){
            if(request.status == null){
                let action = AppUtils.getActionFromPath(model.props.match.path);
                model.props.history.push('/' + action + 'list/' + (model.props.match.params.scroll || '') + '/' + (model.props.match.params.search || '') );
            }
        });
    }

    getSongUrl = () => {
        return AppConfig.dataServiceUrl + '/Song/Stream/' + this.id.value;
    }

    handleDrop = async (acceptedFiles) => {
        this.files.value = acceptedFiles.map(file => Object.assign(file));

        const formData = new FormData();
        formData.append("file", acceptedFiles[0]);
        try {
          const res = await axios.post(AppConfig.dataServiceUrl + '/File/UploadFile', formData);
          if(res.status == 200){
                if(!AppUtils.isNullOrEmpty(res.data.Author)){   
                    this.data.Author.value = res.data.Author;
                    this.data.Title.value = res.data.Title;
                }
                this.data.Filename.value = res.data.TempFile;
          } 
        } catch (ex) {
          console.log(ex);
        }
    }

    render() {
      return (
          <div>
        { this.contentDeleteDialogRender() }
        <Menu title="Upload Music" />
        <div className="user-container">
            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <Link className="btn btn-success" to={'/songlist/' + (this.props.match.params.scroll || '') + '/' + (this.props.match.params.search || '') }>Back</Link>
                    { this.isNew() ? "" : <Button className="btn btn-danger" onClick={ this.showDeleteDialog }>delete</Button> }
                    { this.fieldDefsArray.map(x => { return this.renderField(x) })}
                    
                    { !this.isNew() ?(
                    <div className="input-group mb-3">
                    <audio controls>
                        <source src={ this.getSongUrl() } type="audio/mpeg" />
                            Your browser does not support the audio element.
                    </audio>
                    </div>
                    ) : "" }

                    <Dropzone onDrop={ this.handleDrop }
                    accept=".mp3,audio/*">
                    {({getRootProps, getInputProps}) => (
                        <section>
                        <div  className="baseStyle" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>Drag 'n' drop some files here, or click to select files</p>
                        </div>
                        </section>
                    )}
                    </Dropzone>


                    { this.files.value.map(file => (<div key={file.name}><span>{file.name} - {file.size} bytes</span></div>)) }
                    <button className="btn btn-success" onClick={ this.save }>Save</button>
                </div>
            </div>
        </div>
        </div>
      );
    }
}