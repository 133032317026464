import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import './sh.avatar.component.css';
import AvatarEditor from 'react-avatar-editor'
import Dropzone from 'react-dropzone'

class SHAvatar extends Component {
    constructor(props){
        super(props)
        this.state = {
            image: props.photo.value,
            scaleValue: 1
        }
    }
    
    setEditorRef = (editor) => {
        return this.props.editor.canvas = editor;
    }

    handleBrowse = (event) => {
        let file = event.target.files[0];
        this.setState({ image: file })
    }

    handleDrop = (dropped) => {
        this.setState({ image: dropped[0] })
    }

    handleChange = (event) => {
        this.setState({scaleValue: event.target.value});
    }

    render() {
      return (
        <>
            <Dropzone
                onDrop={this.handleDrop}
                noClick
                noKeyboard
                style={{ width: '500px', height: '500px' }}
            >
                {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                    <AvatarEditor ref={this.setEditorRef} width={148} height={148} borderRadius={74} image={this.state.image} scale={ this.state.scaleValue } />
                    <input {...getInputProps()} />
                </div>
                )}
            </Dropzone>
            <input type="file" onChange={ this.handleBrowse } />
            <input type="range" min="1" max="10" value={ this.state.scaleValue } onChange={this.handleChange } step="0.1" />
        </>  
      );
    }
}

export default withRouter(SHAvatar);