import React, { Component } from "react";
import AppConfig from '../../configurations/app.config';
import AppFetch from "../../core/app.fetch";
import FieldValidation from '../core/field.validation.component';
import FieldText from '../core/field.text.component';
import FieldLabel from '../core/field.label.component';
import SHFormBase from '../core/base/sh.form.base';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";

export default class UserRegistration extends SHFormBase {
    constructor(props){
        super(props)
        this.state = {
            data: {
                ArtistName: '',
                CountryId: '',
                City: '',
                Password: '',
                RePassword: '',
                UserName: ''
            },
        }
        this.modelName = 'UserRegistrationModel';
        this.entityType = 'AspNetUsers';
        this.initValidationState(this.state);
        this.customFieldValidation = [
            { fieldName: 'Password', errorKey: 'FieldSame', validationFunction: async function(model, fieldName){ return model.state.data[fieldName] == model.state.data.RePassword; }, text: 'Field password and retype pasword must be same.' },
            { fieldName: 'RePassword', errorKey: 'FieldSame', validationFunction: async function(model, fieldName){ return model.state.data[fieldName] == model.state.data.Password; }, text: 'Field password and retype pasword must be same.' },
        ]
    }

    handleRegistration = async () => {
        let isValid = await this.handleValidation(null);
        if(!isValid){
            return;
        }
    
        let s = this.state;
        let request = await AppFetch.post(AppConfig.dataServiceUrl + '/account/registration', s.data);
        if(request.status == 400){
            for(let error of request.result.errors){
                toast.warning(error.fieldName + ' - ' + error.text);
            }
            return;
        }
        toast.success('Successfully saved.');
    }

    render() {
      return (
        <div className="user-container">
            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <h1 className="display-6">Registration</h1>
                    <Link className="btn btn-success" to={'/login'}>Back</Link>
                    <div className="input-group mb-3">
                        <FieldLabel labelText="Your artist name" />
                        <FieldText value={ this.state.data.ArtistName } onChange={ (event) => { this.handleChange(event, 'ArtistName'); } } onBlur={ () => { this.handleValidation('ArtistName'); } } name="ArtistName"  />
                        <FieldValidation validStore={ this.state.validationField.ArtistName } />
                    </div>
                    <div className="input-group mb-3">
                        <FieldLabel labelText="Country" />
                        <FieldText value={ this.state.data.CountryId }  onChange={ (event) => { this.handleChange(event, 'CountryId'); } } onBlur={ () => { this.handleValidation('CountryId'); } } name="CountryId"  />
                        <FieldValidation validStore={ this.state.validationField.CountryId } />
                    </div>
                    <div className="input-group mb-3">
                        <FieldLabel labelText="City" />
                        <FieldText value={ this.state.data.City }  onChange={ (event) => { this.handleChange(event, 'City'); } } onBlur={ () => { this.handleValidation('City'); } } name="City"  />
                        <FieldValidation validStore={ this.state.validationField.City } />
                    </div>
                    <div className="input-group mb-3">
                        <FieldLabel labelText="E-Mail (Username)" />
                        <FieldText value={ this.state.data.UserName }  onChange={ (event) => { this.handleChange(event, 'UserName'); } } onBlur={ () => { this.handleValidation('UserName'); } } name="UserName"  />
                        <FieldValidation validStore={ this.state.validationField.UserName } />
                    </div>
                    <div className="input-group mb-3">
                        <FieldLabel labelText="Password" />
                        <FieldText type="Password" autoComplete="new-Password" value={ this.state.data.Password }  onChange={ (event) => { this.handleChange(event, 'Password'); } } onBlur={ () => { this.handleValidation('Password'); } } name="Password"  />
                        <FieldValidation validStore={ this.state.validationField.Password } />
                    </div>
                    <div className="input-group mb-3">
                        <FieldLabel labelText="Retype Password" />
                        <FieldText type="Password" value={ this.state.data.RePassword }  onChange={ (event) => { this.handleChange(event, 'RePassword'); } } onBlur={ () => { this.handleValidation('RePassword'); } } name="RePassword"  />
                        <FieldValidation validStore={ this.state.validationField.RePassword } />
                    </div>
                    <button className="btn btn-success" onClick={ this.handleRegistration }>Register</button>
                </div>
            </div>
        </div>
      );
    }
}