import React from "react";
import Button from "react-bootstrap/Button";
import Draggable from 'react-draggable'; // Both at the same time
import { FiPlay, FiPause } from "react-icons/fi" 
import {BiCaretLeft} from "react-icons/bi" 
import AppConfig from "../../configurations/app.config";
import SHSongPlayerBase from "../core/base/sh.song.player.base";
import './song.strip.component.css';

export default class SongStrip extends SHSongPlayerBase {
    constructor(props){
        super(props)
        this.state = {}
    }

    startRecording = (data) => {
      if(data.status == 0){
        this.props.history.push('/recorder/' + data.id + '/' + this.props.scroll + '/' + (this.props.search || ''));
      }else{
        this.props.history.push('/dancecut/' + data.id + '/' + this.props.scroll + '/' + (this.props.search || ''));
      }
    }

    getThumbnailImage = (x) => {
      let url = AppConfig.dataServiceUrl + '/Dance/ThumbnailDanceCut/' + x.id;
      return x.status == 1 || x.status == 2 ? {backgroundImage: 'url("'+ url +'")' } : {};
    }

    getCutStatusColor = (x) => {
      let style = {};
      switch(x.status){
        case 0:
          style.backgroundColor = '#FFD700';
          break;
        case 1:
          style.backgroundColor = '#FF8C00';
          break;
        case 2:
            style.backgroundColor = '#32CD32';
            break;
      }
      return style;
    }

    render() {
      return (
          <div className="song-cut-cutter-container">
            <div className="sticky-top">
            <Button className="btn btn-danger" onClick={ this.play }>{ this.isPlaying.value ? <FiPause /> : <FiPlay /> }</Button>
            <Button>Approve</Button>
            </div>
            <audio className="hide-panel" onLoadedMetadata={ this.audioLoaded } onTimeUpdate={ this.audioOnTimeUpdate } controls>
              <source src={ this.getSongUrl() } type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
            {this.props.currentDanceCutId.value}
            <div className="cutter-strip-container">
              <div className="cutter-left-strip" style={{minHeight: this.totalHeight.value}}>
              { this.cuts.value.map(x => { return (
                <div className="cutter-cut-strip" key={x.position} onClick={ () => this.startRecording(x) } style={{ height: this.calculateDeltaValue(x) * this.secondHeight }}>
                  <div  className="dancecut-thumbnail" style={ this.getThumbnailImage(x) }>
                    <div className="cutter-time-strip">{ this.calculateDelta(x) }</div>
                    <div className="cutter-status-strip" style={ this.getCutStatusColor(x) }></div>
                  </div>
                </div>) }) }
                <div className="cutter-current-position" style={{top: this.currentHeight.value}}><div className="cutter-current-time">{ this.getCurrentTime() }</div> <div className="cutter-current-arrow"><BiCaretLeft size={40} /></div></div>
              </div>
              <div className="cutter-right-strip">
              <Draggable
                axis="y"
                bounds="parent"
                handle=".handle"
                defaultPosition={null}
                position={{x: 0, y: this.currentHeight.value}}
                grid={[1, 1]}
                scale={1}
                onStop={ (event, dragElement) => this.handleStopCurrent(event, dragElement) }>
                <div  style={{top: this.currentHeight.value}}>
                  <div className="handle-cutter-current-position"><div className="cutter-current-arrow"><BiCaretLeft className="handle" size={40} /></div></div>
                </div>
              </Draggable>
              </div>
            </div>

 
        </div>
      );
    }
}