import AppUtils from "./app.utils";

class AppOData {
    constructor(){
    }

    recurseProperties = function(o, level, useAmp) {
        if (o == null || o == "" || o.hasOwnProperty(""))
            return "";

        var query = "";
        var selects = new Array();
        var orderbys = new Array(); // for ordering a collections
        var expands = new Array();
        var filters = new Array();

        var prefix = level > 0 ? "(" : "";
        var sufix = level > 0 ? ")" : "";
        var separator = level == 0 || useAmp === true ? "&" : ";";

        for (var prop in o) {
            if (prop == "__filters") {
                filters = filters.concat(o[prop]); // Add other filter expressions
            } else if (typeof (o[prop]) == "object" && o[prop] != null) {
                expands.push(prop);
            } else {
                selects.push(prop);
                if (!AppUtils.isNullOrEmpty(o[prop])) {
                    orderbys.push(prop + ' ' + o[prop]);
                }

            }
        }

        if (level > 0) {
            query = prefix + "$select=" + selects.join(",");
            if (orderbys.length > 0) {
                query += ";$orderby=" + orderbys.join();
            }

            if (filters.length > 0) {
                var filtersStr = ";$filter=(";
                for (var i = 0, num = filters.length; i < num; i++) {
                    filtersStr += filters[i].expr;
                    if (i < num - 1 && filters[i].hasOwnProperty("isAndOperator")) {
                        filtersStr += filters[i + 1].isAndOperator ? " and " : " or ";
                    }
                }
                filtersStr += ")";
                query += filtersStr;
            }
        }

        if (expands.length > 0) {
            if (level > 0)
                query += separator + "$expand=";

            for (var i = 0; i < expands.length; i++) {
                if (i > 0)
                    query += ",";

                var child = expands[i];
                query += child + this.recurseProperties(o[child], level + 1);
            }
        }

        query += sufix;
        return query;
    };
}


export default new AppOData();