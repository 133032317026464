import React from "react";
import AppConfig from '../../configurations/app.config';
import Menu from '../menu/menu.component';
import SHAvatar from '../sh.avatar/sh.avatar.component';
import AppUtils from "../../core/app.utils";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import SHFormBase from '../core/base/sh.form.base';

export default class User extends SHFormBase {
    constructor(props){
        super(props)
        this.state = {}
        this.showChagePhoto = this.useState(false);

        this.fieldDefs = {
            FirstName: {
                controlType: 'Textbox',
                label: 'First Name'
            },
            LastName: {
                controlType: 'Textbox',
                label: 'Last Name'
            },
            ArtistName: {
                controlType: 'Textbox',
                label: 'Artist Name'
            },
            UserName: {
                controlType: 'Textbox',
                label: 'Username'
            },
            Password: {
                controlType: 'Textbox',
                label: 'Password',
                showOnEdit: false,
            },
            Role: {
                controlType: 'Combobox',
                sourceType: 'Custom',
                enum: 'UserRoles',
                entityId: 'Role',
                label: 'Role'
            },
            City: {
                controlType: 'Textbox',
                label: 'City'
            },
            CountryId: {
                label: 'Country',
                controlType: 'Combobox',
                sourceType: 'DataLayer',
                entityType: 'Country',
                textField: 'Name',
                entityId: 'Id',
            },
            Photo: {
                controlType: 'Textbox',
                showOnEdit: false,
                showOnNew: false
            }
        }
        this.entityType = 'User';
        this.initFields(this);
        this.initValidationState(this);
        this.editor = { canvas: null };
    }


    getComboboxUrl = (fieldDef) => {
        return AppConfig.dataServiceUrl + '/Admin/' + fieldDef.entityType
    }

    getUrl = () => {
        return AppConfig.dataServiceUrl + '/' + this.entityType + '/id:string?id=' + this.id.value;
    }

    changePhoto = () => {
        this.showChagePhoto.value = true;
    }

    cancelPhoto = () => {
        this.showChagePhoto.value = false;
    }

    applyPhoto = () => {
        if (this.editor.canvas) {
            // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
            // drawn on another canvas, or added to the DOM.
            const canvas = this.editor.canvas.getImage();
      
            // If you want the image resized to the canvas size (also a HTMLCanvasElement)
            const canvasScaled = this.editor.canvas.getImageScaledToCanvas();

            let base64 = canvasScaled.toDataURL();

            this.data.Photo.value = base64;
        }

        this.showChagePhoto.value = false;
    }

    render() {
      return (
          <div>
              { this.contentDeleteDialogRender() }
        <Menu title="User List" />
        <div className="user-container">
            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <Link className="btn btn-success" to={'/userlist/'+ (this.props.match.params.scroll || '') + '/' + (this.props.match.params.search || '')}>Back</Link>
                    { this.isNew() ? "" : <Button className="btn btn-danger" onClick={ this.showDeleteDialog }>delete</Button> }
                    <div className="input-group mb-3">
                        { this.showChagePhoto.value ?  
                        <div><SHAvatar editor={ this.editor } photo={ this.data.Photo } /> <button onClick={this.cancelPhoto}>Cancel</button> <button onClick={ this.applyPhoto }>Ok</button> </div> :
                        <div>
                            <img className="avatar-image" src={ AppUtils.isNullOrEmpty(this.data.Photo.value) ? '/images/noperson.png' : this.data.Photo.value } width="148" height="148"  />
                            <button onClick={ this.changePhoto }>Change photo</button>
                        </div>
                        }
                    </div>
                    { this.fieldDefsArray.map(x => { return this.renderField(x) })}

                    <button className="btn btn-success" onClick={ this.save }>Save</button>
                </div>
            </div>
        </div>
        </div>
      );
    }
}