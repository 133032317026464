import React from "react";
import { withRouter } from "react-router-dom";
import { FiSearch, FiXCircle } from "react-icons/fi"; 
import SHBaseComponent from "../core/base/sh.base.component";

import './menu.component.css';
import AppUtils from "../../core/app.utils";

class SearchTexbox extends SHBaseComponent {
    constructor(props){
        super(props)
        this.state = {}

        this.searchText = this.props.searchText;
        this.searchTextCurrent = this.useState(this.props.searchText.value);
    }

    onChange = (event) => {
        this.searchTextCurrent.value = event.target.value;
    }

    clearText = () => {
        this.searchTextCurrent.value = '';
        this.searchText.value = '';
    }

    search = () => {
        this.searchText.value = this.searchTextCurrent.value;
    }

    render() {
      return (
       <>
        <div className="input-group">
            <input type="text" value={ this.searchTextCurrent.value } onChange={ this.onChange } className="form-control" />
            <span className={ AppUtils.isNullOrEmpty(this.searchTextCurrent.value) ? "clear-button hide-panel" : "clear-button"}>
                <FiXCircle size={26} onClick={ this.clearText } />
            </span>
        </div>
        <button className="btn btn-primary"><FiSearch size={24} onClick={ this.search } color="white" /></button>
       </>
      );
    }
}

export default withRouter(SearchTexbox);