class AppUser {
    getData = () => {
        let data = localStorage.getItem('AppUser');
        return data ? JSON.parse(data) : null;
    }

    setData = (data) => {
        localStorage.setItem('AppUser', JSON.stringify(data));
    }

    getUserId = () => {
        let data = this.getData();
        if(data == null) {
            return null;
        }

        let t = this.parseJwt(data.token);
        return t.sid || null;
    }

    parseJwt = (token) => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
    };

    logout = () => {
        localStorage.removeItem('AppUser');
    }
}

export default new AppUser();