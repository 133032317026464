class AppUtils {
    deepClone = (value) => {
        return JSON.parse(JSON.stringify(value));
    }

    isNullOrEmpty = (value) => {
        return value == null || value == '' || (typeof myVar === 'string' &&  value.trim() == '');
    }

    isValidationValid = (validation) => {
        for(let prop in validation) {
            if(validation[prop] && validation[prop].length){
                return false;
            }
        }
        return true;
    }

    getActionFromPath = (url) => {
        let v = url.split("/");
        return v.length >= 2 ? v[1] : '';
    }

    getParameterCaseInsensitive = (object, key) => {
        const asLowercase = key.toLowerCase();
        return object[Object.keys(object)
            .find(k => k.toLowerCase() === asLowercase)
        ];
    }
    
    getPropByPath = (obj, desc) => {
        if (obj == null) {
            return obj;
        }
        var arr = desc.split(".");
        while (arr.length && (obj = obj[arr.shift()]));
        return obj;
    }

    getGuid = () => {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
    }

    isEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    };
}

export default new AppUtils();