import AppUser from "./app.user";

class AppFetch {
    post = async (url, data, returnResult) => {
      return this.request(url, data, returnResult, 'POST')
    }

    patch = async (url, data, returnResult) => {
      return this.request(url, data, returnResult, 'PATCH')
    }

    delete = async (url, returnResult) => {
      return this.request(url, null, returnResult, 'DELETE')
    }

    request = async (url, data, returnResult, method) => {
      let user = AppUser.getData();
      
      let headers = {
        'Content-Type': 'application/json'
      }

      if(user){
        headers.Authorization =  'Bearer ' + user.token;
      }

      let result = await fetch(url, {
            method: method, // *GET, POST, PUT, DELETE, etc.
            headers: headers,
            body: JSON.stringify(data) // body data type must match "Content-Type" header
          });

        if(returnResult){
          return result;
        }

        switch(result.status){
          case 200:
          case 201:
            return result.json()
          case 400:
            return { status: result.status, result: await result.json() } 
          case 401:
            AppUser.logout();
            window.location.href = '/login'
            break;
        } 
    }
    
    get = async (url) => {
      let user = AppUser.getData();
      let headers = {
        'Content-Type': 'application/json'
      }

      if(user){
        headers.Authorization =  'Bearer ' + user.token;
      }

      let result = await fetch(url, {
          method: 'GET', // *GET, POST, PUT, DELETE, etc.
          headers: headers
      });

      switch(result.status){
        case 200:
          return result.json()
        case 401:
          AppUser.logout();
          window.location.href = '/login'
          break;
      }
  }
}

export default new AppFetch();