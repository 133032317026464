import React, { Component } from "react";
import Menu from '../menu/menu.component';
import SHListBase from '../core/base/sh.list.base';
import AppUtils from "../../core/app.utils";

export default class UserList extends SHListBase {
    constructor(props){
        super(props)
        this.entityType = 'User';
    }

    renderContent = ({style, data}) => {
      return (
        <div key={data.Id} className="card" style={style}  onClick={ () => this.goLink(data.Id, 'user')}>
          <div className="row no-gutters">
            <div className="col-auto">
              <img src={ data.Photo ?  data.Photo : '/images/noperson.png' } className="img-fluid avatar-image" alt="" width="64" />
            </div>
            <div className="col">
              <div className="card-block px-2">
                  <h4 className="card-title"><button className="btn btn-link padding-left0">{data.FirstName}, {data.LastName}</button></h4>
                  <p className="card-text">{data.Email}</p>
              </div>
            </div>
          </div>
        </div>
      );
    }

    getSearchCriteria = () => {
      let searchText = this.searchText.value;
      if(AppUtils.isNullOrEmpty(searchText)){
        return '';
      }
      return "&$filter=contains(FirstName, '" + searchText + "') or contains(LastName, '" + searchText + "') or contains(Email, '" + searchText + "')";
    }


    render() {
      return (
        <div>
          <Menu title="User List" searchText={this.searchText} showSearch={true} showNew={true} />
          { this.renderList() }
        </div>
      );
    }
}