import React, { Component } from "react";
import AppConfig from '../../configurations/app.config';
import AppFetch from "../../core/app.fetch";
import AppUtils from "../../core/app.utils";
import AppUser from "../../core/app.user";
import { withRouter } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import './login.component.css';

class Login extends Component {
    constructor(props){
        super(props)
        this.state = {
            username: '',
            password: '',
            validation: '',
            validationField: {
                username: [],
                password: []
            }
        }
    }

    handleChange = (event, name) => {
        let v = {};
        v[name] = event.target.value;
        this.setState(v, function() {
            this.handleValidation(name);
        });
    }

    handleValidation = (name) => {
        let s = this.state;
        let valid = AppUtils.deepClone(s.validationField);

        if(name == null || name == 'username'){
            valid.username = [];
            if(AppUtils.isNullOrEmpty(s.username)){
                valid.username = [{ ErrorKey: "MandatoryField", Text: "Field must be unique." }];
            }
        }

        if(name == null || name == 'password'){
            valid.password = [];
            if(AppUtils.isNullOrEmpty(s.password)){
                valid.password = [{ ErrorKey: "MandatoryField", Text: "Field must be unique." }];
            }
        }

        this.setState({ validationField: valid});

        return AppUtils.isValidationValid(valid);
    }

    handleLogin = async () => {
        let s = this.state;

        if(!this.handleValidation()){
            return;
        }
        
        try{
            let result = await AppFetch.post(AppConfig.dataServiceUrl + '/account/login', s, true);

            switch(result.status){
                case 200:
                case 201:
                    let data = await result.json();
                    AppUser.setData(data);
                    this.props.history.push('/songlist/0');
                case 401:
                    this.setState({ validation: 'Invalid username or password.' });
                    break;
                default:
                    this.setState({ validation: 'Error connecting to service.' });
                    break;
            } 
        }catch(er){
            this.setState({ validation: 'Error connecting to service.' });
        }
    }

    render() {
      return (
        <div className="login-container">
            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <h1 className="display-6">Administration</h1>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">Username</span>
                        </div>
                        <input type="text" value={this.state.username} onChange={ (event) => { this.handleChange(event, 'username'); } } autoComplete="new-password" className="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                        { this.state.validationField.username.length ? <div className="input-group mt-3">{ this.state.validationField.username.map((error) => (<Alert key={error.ErrorKey} variant="danger">{ error.Text }</Alert>))}</div> : '' }
                    </div>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">Password</span>
                        </div>
                        <input type="password" value={this.state.password} onChange={ (event) => { this.handleChange(event, 'password'); } } autoComplete="new-password" className="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                        { this.state.validationField.password.length ? <div className="input-group mt-3"> { this.state.validationField.password.map((error) => (<Alert key={error.ErrorKey} variant="danger">{ error.Text }</Alert>))}</div> : '' }
                    </div>
                    <button className="btn btn-success" onClick={ this.handleLogin }>Login</button>
                    { this.state.validation != '' ? <div className="input-group mt-3"><Alert variant="danger">{ this.state.validation }</Alert></div> : '' }
                </div>
            </div>
        </div>
      );
    }
}

export default withRouter(Login);