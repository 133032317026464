import React, { Component } from "react";
import Login from './components/login/login.component';
import User from './components/user/user.component';
import { Switch, Route, withRouter } from "react-router-dom";
import AppUser from "./core/app.user";
import SongList from './components/song/song.list.component';
import CommentList from './components/comment/comment.list.component';
import Comment from './components/comment/comment.component';
import VideoList from './components/video/video.list.component';
import Video from './components/video/video.component';
import SongCutTemplateList from './components/song.cut.template/song.cut.template.list.component';
import SongCutTemplate from './components/song.cut.template/song.cut.template.component';
import Song from './components/song/song.component';
import DanceList from './components/dance/dance.list.component';
import Dance from './components/dance/dance.component';
import EntityMetadata from './configurations/entity.metadata';
import UserList from './components/user/user.list.component';
import UserRegistration from './components/user/user.registration.component';
import './App.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VideoRecorder from "./components/video.recorder/video.recorder.component";
import DanceCut from "./components/dance.cut/dance.cut.component";

class App extends Component {
  constructor(props){
    super(props)
    this.PublicRoutes = ['/registration']
    this.state = {
      isLoading: true
    }
  }

  componentDidMount = async () => {
    //Initialize application
    await EntityMetadata.init();
    this.setState({ isLoading: false });

    let user = AppUser.getData();
    if(user == null && this.PublicRoutes.indexOf(this.props.location.pathname) == -1){
      this.props.history.push('/login');
    }
  }

  render() {
    return (
      <div className="App">
        <ToastContainer />
        { this.state.isLoading ? <span>loading...</span> : 
        <Switch>
          <Route path='/login' exact={true} component={ Login } />
          <Route path='/songlist' exact={true} component={ SongList } />
          <Route path='/songlist/:scroll' exact={true} component={ SongList } />
          <Route path='/songlist/:scroll/:search' exact={true} component={ SongList } />
          <Route path='/dancelist' exact={true} component={ DanceList } />
          <Route path='/dancelist/:scroll' exact={true} component={ DanceList } />
          <Route path='/dancelist/:scroll/:search' exact={true} component={ DanceList } />
          <Route path='/videolist' exact={true} component={ VideoList } />
          <Route path='/videolist/:scroll' exact={true} component={ VideoList } />
          <Route path='/videolist/:scroll/:search' exact={true} component={ VideoList } />
          <Route path='/songcuttemplatelist' exact={true} component={ SongCutTemplateList } />
          <Route path='/songcuttemplatelist/:scroll' exact={true} component={ SongCutTemplateList } />
          <Route path='/songcuttemplatelist/:scroll/:search' exact={true} component={ SongCutTemplateList } />
          <Route path='/commentlist' exact={true} component={ CommentList } />
          <Route path='/commentlist/:scroll' exact={true} component={ CommentList } />
          <Route path='/commentlist/:scroll/:search' exact={true} component={ CommentList } />
          <Route path='/userlist' exact={true} component={ UserList } />
          <Route path='/userlist/:scroll' exact={true} component={ UserList } />
          <Route path='/userlist/:scroll/:search' exact={true} component={ UserList } />
          <Route path='/registration' exact={true} component={ UserRegistration } />
          <Route path='/user/:id/:scroll' exact={true} component={ User } />
          <Route path='/user/:id/:scroll/:search' exact={true} component={ User } />
          <Route path='/song/:id/:scroll' exact={true} component={ Song } />
          <Route path='/song/:id/:scroll/:search' exact={true} component={ Song } />
          <Route path='/songcuttemplate/:id/:scroll' exact={true} component={ SongCutTemplate } />
          <Route path='/songcuttemplate/:id/:scroll/:search' exact={true} component={ SongCutTemplate } />
          <Route path='/dance/:id/:scroll' exact={true} component={ Dance } />
          <Route path='/dance/:id/:scroll/:search' exact={true} component={ Dance } />
          <Route path='/dancecut/:id/:scroll' exact={true} component={ DanceCut } />
          <Route path='/dancecut/:id/:scroll/:search' exact={true} component={ DanceCut } />
          <Route path='/video/:id/:scroll' exact={true} component={ Video } />
          <Route path='/video/:id/:scroll/:search' exact={true} component={ Video } />
          <Route path='/recorder/:id/:scroll' exact={true} component={ VideoRecorder } />
          <Route path='/recorder/:id/:scroll/:search' exact={true} component={ VideoRecorder } />
          <Route path='/comment/:id/:scroll' exact={true} component={ Comment } />
          <Route path='/comment/:id/:scroll/:search' exact={true} component={ Comment } />
          <Route path='/' exact={true} component={ SongList } />
        </Switch>
        }
      </div>
    );
  }
}

export default withRouter(App);