import React from "react";
import Menu from '../menu/menu.component';
import { Link } from "react-router-dom";
import SHFormBase from '../core/base/sh.form.base';
import AppConfig from "../../configurations/app.config";
import DateCreatedLabel from '../core/date.created.label.component';
import AppUtils from "../../core/app.utils";
import Button from "react-bootstrap/Button";

export default class Comment extends SHFormBase {
    constructor(props){
        super(props)
        this.state = {}

        this.fieldDefs = {
            Text: {
                controlType: 'Textbox',
                label: 'Comment'
            },
            Status: {
                controlType: 'Combobox',
                sourceType: 'Custom',
                enum: 'CommentStatus',
                label: 'Status'
            },
            VideoId: {
                label: 'Video',
                controlType: 'LightboxList',
                entityType: 'Video',
                textField: 'Dance.Title',
                entityId: 'Id',
                searchFilter: function(searchText) { return "contains(Dance/Title,'" + searchText + "') or contains(Dance/SongCutTemplate/Song/Author,'" + searchText + "') or contains(Dance/SongCutTemplate/Song/Title,'" + searchText + "')" },
                source: function({ startIndex, stopIndex }) {  return AppConfig.dataServiceUrl + '/Admin/Video?$expand=Dance($expand=SongCutTemplate($expand=Song)),CreatedBy' + this.getSearchCriteria(this.searchText.value) + this.getSkipAndTakeUrl({ startIndex, stopIndex }); },
                renderContent: function({style, data}){ return ( 
                    <div key={data.Id} className="card" style={style} onClick={() => { this.selectItem(data) }}>
                    <div className="row no-gutters">
                      <div className="col-auto">
                        <img src={ '/images/noperson.png' } className="img-fluid" alt="" width="64" />
                      </div>
                      <div className="col">
                        <div className="card-block px-2">
                            <h4 className="card-title">{data.Dance.Title}</h4>
                            <DateCreatedLabel createdDate={data.CreatedDate} firstName={data.CreatedBy.FirstName} lastName={data.CreatedBy.LastName} />
                        </div>
                      </div>
                    </div>
                  </div>) }
            },
        }

        this.area = 'Admin';
        this.entityType = 'Comment';
        this.initFields(this);
        this.initValidationState(this);

        this.expandFields.Video = { 
            Dance: {}
        }
    }

    componentDidMount(...args) {
        super.componentDidMount.apply(this, args);

        this.events.postSave.add(function(model, isNew, request){
            if(request.status == null){
                let action = AppUtils.getActionFromPath(model.props.match.path);
                model.props.history.push('/' + action + 'list/' + model.props.match.params.scroll+ '/' + (model.props.match.params.search || ''));
            }
        });
    }

    render() {
      return (
          <div>
            { this.contentDeleteDialogRender() }
            <div className={ this.showLightboxList.value ? 'hide-panel' : '' }>
                <Menu title="Comments" />
                <div className="user-container">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <Link className="btn btn-success" to={'/commentlist/' + (this.props.match.params.scroll || '') + '/' + (this.props.match.params.search || '')}>Back</Link>
                            { this.isNew() ? "" : <Button className="btn btn-danger" onClick={ this.showDeleteDialog }>Delete</Button> }
                            { this.fieldDefsArray.map(x => { return this.renderField(x) })}
                            <button className="btn btn-success" onClick={ this.save }>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      );
    }
}