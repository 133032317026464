import React, { Component } from "react";
import AppFetch from "../../../core/app.fetch";
import AppConfig from '../../../configurations/app.config';
import AppUtils from "../../../core/app.utils";
import SHBaseComponent from "./sh.base.component";
import { List, AutoSizer } from "react-virtualized";

export default class SHListBase extends SHBaseComponent {
    constructor(props){
        super(props);

        this.state = {}

        this.isLoading = this.useState(true);
        this.items = this.useStateArray();

        this.itemsPerPage = 15;
        this.page = 1
        this.totalCount = this.useState(0);
        this.entityType = null;
        this.area = null;
        this.loaded = [1];
        this.initScroll = this.props.match && this.props.match.params.scroll ? parseInt(this.props.match.params.scroll) : 0;
        this.scrollTop = this.useState(0)
        this.isInit = true;
        this.rowHeight = 90;
        this.listElement = React.createRef();

        this.searchText = this.useState(this.props.match && this.props.match.params.search ? this.props.match.params.search : '');
    }

    goLink = (id, action) => {
      let a = this.listElement.current.getElementsByClassName('ReactVirtualized__Grid ReactVirtualized__List')[0].scrollTop;
      this.props.history.push('/' + action + '/' + id + '/' + a + '/' + this.searchText.value);
    }

    nextPage = (idx) => {
      let page = Math.ceil((idx+1)/this.itemsPerPage);
      console.log('Page' + page);
      if(this.loaded.indexOf(page) == -1){
       this.loaded.push(page);
       let end = page * this.itemsPerPage;
       this.fillData({ startIndex: end - this.itemsPerPage, stopIndex: this.itemsPerPage });
      }
   }

   getScroll = (event) => {
    if(this.isInit){
      this.isInit = false;
      this.scrollTop.value = this.initScroll;
    }else{
      this.scrollTop.value = event.scrollTop;
    }
  }

    getSkipAndTakeUrl = ({ startIndex, stopIndex }) => {
      return '&$skip=' + startIndex + '&$top=' + stopIndex + '&$count=true';
    }

    getSearchCriteria = () => {
      return '';
    }

    getUrl = ({ startIndex, stopIndex }) => {
      let area = (this.area) ? '/' + this.area : ''; 
      let url = AppConfig.dataServiceUrl + area + '/' + this.entityType + '?';
      let search = this.getSearchCriteria();
      if(!AppUtils.isNullOrEmpty(search)){
        url += '&$filter=' + search;
      }
      url += this.getSkipAndTakeUrl({ startIndex, stopIndex }); 
      return url;
    }

    componentDidMount = async () => {
      await this.fillData({ startIndex: 0, stopIndex: this.itemsPerPage });
      if(this.initScroll > 0){
        let currentPage = Math.ceil((this.scrollTop.value / this.rowHeight) / this.itemsPerPage);
        
        if(currentPage > 1){
          await this.fillData({ startIndex: ((currentPage-1) * this.itemsPerPage), stopIndex: this.itemsPerPage });  
        }

        await this.fillData({ startIndex: (currentPage * this.itemsPerPage), stopIndex: this.itemsPerPage });
        this.scrollTop.value = this.scrollTop.value +1
      }

      this.searchText.subscribe((newValue) => {
        this.items.clearInstance();
        this.loaded.splice(0, this.loaded.length);
        this.listElement.current.getElementsByClassName('ReactVirtualized__Grid ReactVirtualized__List')[0].scrollTop = 0;
        
        let action = AppUtils.getActionFromPath(this.props.match.path);
        this.props.history.push('/' + action + '/0/' + newValue);
        this.fillData({ startIndex: 0, stopIndex: this.itemsPerPage });
      });
    }

    fillData = async ({ startIndex, stopIndex }) => {
      this.isLoading.value = true;
      let url = this.getUrl({ startIndex, stopIndex });
      let result = await AppFetch.get(url);


      let res = AppUtils.deepClone(result);
      if(res.value){
        let start = startIndex;
        for(let row of res.value){
          this.items.value[start] = row;
          start++
        }
        this.items.valueHasMutated();
        this.totalCount.value =  res.count;
      }else{
        this.items.value = res;
      }
      
      this.isLoading.value = false;
    }

    content = ({key, index, style}) =>  {
      console.log(index);
      this.nextPage(index);

      let data = this.items.value[index];
      if(data == null){
        return (<div key={key}>Loading...</div>)
      }

      return this.renderContent({key, index, style, data});
    }
    
    renderList = () => {
      return (
          <div ref={this.listElement} style={{width: '100%', height:'90vh'}}>
            <AutoSizer ref={this.listElement}>
            {({width, height}) => {  
            return (<List width={width} 
                          height={height} 
                          rowHeight={this.rowHeight}
                          rowCount={this.totalCount.value} 
                          rowRenderer={ this.content }
                          onScroll={this.getScroll}
                          scrollTop={this.scrollTop.value} />
                ) }}
            </AutoSizer>
            { (!this.isLoading.value && this.items.value.length == 0) ? <div className="alert alert-warning">No Items Found.</div> : '' }
            { this.isLoading.value ? <div className="spinner-border text-danger" role="status"></div> : "" }
        </div>
      )
    }
}