import React from "react";
import Menu from '../menu/menu.component';
import { Link } from "react-router-dom";
import SHFormBase from '../core/base/sh.form.base';
import SongStrip from "../song.strip/song.strip.component";
import AppConfig from "../../configurations/app.config";
import AppUtils from "../../core/app.utils";
import Button from "react-bootstrap/Button";
import AppFetch from "../../core/app.fetch";
import { toast } from 'react-toastify';
import DancePreview from "../dance.preview/dance.preview.component";
import DateCreatedLabel from '../core/date.created.label.component';

export default class Dance extends SHFormBase {
    constructor(props){
        super(props)
        this.state = {}
        this.fieldDefs = {
            Title: {
                controlType: 'Textbox',
                label: 'Dancing Theme'
            },
            SongCutTemplateId: {
                label: 'Song cut template',
                controlType: 'LightboxList',
                entityType: 'SongCutTemplate',
                textField: 'Title',
                entityId: 'Id',
                searchFilter: function(searchText) { return "contains(Title, '" + searchText + "') or contains(Song/Title, '" + searchText + "') or contains(Song/Author, '" + searchText + "')" },
                source: function({ startIndex, stopIndex }) {  return AppConfig.dataServiceUrl + '/Admin/SongCutTemplate?$expand=CreatedBy,Song' + this.getSearchCriteria(this.searchText.value) + this.getSkipAndTakeUrl({ startIndex, stopIndex }); },
                renderContent: function({style, data}){ return ( <div key={data.Id} className="card" style={style} onClick={() => { this.selectItem(data) }}>
                <div className="row no-gutters">
                  <div className="col-auto">
                    <img src={ data.CreatedBy.Photo ? data.CreatedBy.Photo : '/images/noperson.png' } className="img-fluid avatar-image" alt="" width="64" />
                  </div>
                  <div className="col">
                    <div className="card-block px-2">
                        <button className="btn btn-link padding-left0" >{data.Title}</button>
                        <h6 className="card-text">{ data.Song.Author + ', ' + data.Song.Title}</h6>
                        <DateCreatedLabel createdDate={data.CreatedDate} firstName={data.CreatedBy.FirstName} lastName={data.CreatedBy.LastName} />
                    </div>
                  </div>
                </div>
              </div>) }
            }
        }

        this.songCut = this.useStateArray();
        this.songId = this.useState(0);
        this.currentDanceCutId = this.useState(0);

        this.area = 'Admin';
        this.entityType = 'Dance';
        this.initFields(this);
        this.initValidationState(this);

        this.expandFields.SongCutTemplate = {};
        this.expandFields.DanceCut = {};
    }

    componentDidMount(...args) {
        super.componentDidMount.apply(this, args);
        
        this.events.postLoad.add(function(model, data){
            model.songId.value = data.SongCutTemplate.SongId;
            model.songCut.pushAll(data.DanceCut);
        });

        this.events.postSave.add(function(model, isNew, request){
            if(request.status == null){
                let action = AppUtils.getActionFromPath(model.props.match.path);
                model.props.history.push('/' + action + 'list/' + model.props.match.params.scroll + '/' + (model.props.match.params.search || ''));
            }
        });
    }

    margeVideo = async () => {
        let url = AppConfig.dataServiceUrl + this.getArea() + '/Marge/' + this.id.value;
        let request = await AppFetch.get(url);
        toast.success('Successfully marged.');
    }

    render() {
      return (
          <div>
              { this.contentDeleteDialogRender() }
            <div className={ this.showLightboxList.value ? 'hide-panel' : '' }>
                <Menu title="Record Clips" />
                <div className="user-container">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <Link className="btn btn-success" to={'/dancelist/' + (this.props.match.params.scroll || '') + '/' + (this.props.match.params.search || '')}>Back</Link>
                            { this.isNew() ? "" : <><Button className="btn btn-danger" onClick={ this.showDeleteDialog }>Delete</Button><Button className="btn btn-danger" onClick={ this.margeVideo }>Marge</Button></> }

                            { this.fieldDefsArray.map(x => { return this.renderField(x) })}
                            <button className="btn btn-success" onClick={ this.save }>Save</button>
                            <DancePreview danceCutId={ this.currentDanceCutId } dataCuts={ this.songCut }  />
                            { this.songId.value > 0 ? <SongStrip history={ this.props.history } songId={ this.songId } dataCuts={ this.songCut } currentDanceCutId={this.currentDanceCutId} scroll={this.props.match.params.scroll} search={this.props.match.params.search} /> : '' }
                        </div>
                    </div>
                </div>
            </div>
        </div>
      );
    }
}