import React, { Component } from "react";
import Menu from '../menu/menu.component';
import CardList from '../card.list/card.list.component';
import { Link } from "react-router-dom";
import AppConfig from '../../configurations/app.config';
import SHListBase from '../core/base/sh.list.base';
import DateCreatedLabel from '../core/date.created.label.component';
import AppUtils from "../../core/app.utils";

export default class DanceList extends SHListBase {
    constructor(props){
      super(props)
      this.area = 'Admin';
      this.entityType = 'Dance';
    }

    getUrl = ({ startIndex, stopIndex }) => {
      return AppConfig.dataServiceUrl + '/' + this.area + '/' + this.entityType + '?$select=Title,CreatedDate,Id,Percentage,Status&$expand=CreatedBy($select=FirstName,LastName,Photo),SongCutTemplate($expand=Song($select=Title,Author))' + this.getSearchCriteria() + this.getSkipAndTakeUrl({ startIndex, stopIndex });
    }

    renderContent = ({style, data}) => {
      return (
        <div key={data.Id} className="card" style={style} onClick={ (id) => this.goLink(data.Id, 'dance')}>
          <div className="row no-gutters">
            <div className="col-auto">
              <img src={ data.CreatedBy.Photo ? data.CreatedBy.Photo : '/images/noperson.png' } className="img-fluid avatar-image" alt="" width="64" />
            </div>
            <div className="col">
              <div className="card-block px-2">
                  <div>{data.Title}</div>
                  <div>{ data.SongCutTemplate.Song.Author }, { data.SongCutTemplate.Song.Title }</div>
                  <div>{data.Percentage < 100 ? data.Percentage + '%' : 'Finished' }</div>
                  <DateCreatedLabel createdDate={data.CreatedDate} firstName={data.CreatedBy.FirstName} lastName={data.CreatedBy.LastName} />
              </div>
          </div>
          </div>
        </div>
      );
    }

    getSearchCriteria = () => {
      let searchText = this.searchText.value;
      if(AppUtils.isNullOrEmpty(searchText)){
        return '';
      }
      return "&$filter=contains(SongCutTemplate/Song/Title, '" + searchText + "') or contains(SongCutTemplate/Song/Author, '" + searchText + "') or contains(Title, '" + searchText + "') or contains(CreatedBy/Email, '" + searchText + "')";
    }

    render() {
      return (
        <div>
          <Menu title="Record Clips" searchText={this.searchText} showSearch={true} showNew={true}  />
          { this.renderList() }
        </div>
      );
    }
}