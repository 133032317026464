import React from "react";
import { Link } from "react-router-dom";
import AppConfig from "../../configurations/app.config";
import AppFetch from "../../core/app.fetch";
import SHBaseComponent from "../core/base/sh.base.component";
import { FiClock, FiRotateCcw, FiCamera } from "react-icons/fi" 
import './video.recorder.component.css';
import axios from "axios";

export default class VideoRecorder extends SHBaseComponent {
    constructor(props){
      super(props)
      this.state = {};

      this.area = 'Admin';
      this.entityType = 'DanceCut';
      this.id = isNaN(this.props.match.params.id) ? (this.props.match.params.id || -1) : parseInt(this.props.match.params.id);
      this.songId = this.useState(null);
      this.danceId = this.useState(null);
      this.danceCut = { endTime: null, startTime: null };
      this.timeSettingShow = this.useState(false);
      this.waitSeconds = [{ value: 1, label: '1s' }, { value: 2, label: '2s' }, { value: 3, label: '3s' }, { value: 5, label: '5s' }, { value: 10, label: '10s' }];
      this.selectedTime = this.useState(3);
      this.audioElement = null;
      this.videoElement =  React.createRef();
      this.videoReplyElement =  React.createRef();
      this.showReply = this.useState(false);
      this.stream = null;
      this.recordingStream = null;
      this.recordingStatus = this.useState(0);
      this.recordingProgress = this.useState(0);

      this.hasRecordedVideo = this.useState(false);
      this.interval = null;
      this.currentSecound = this.useState(0);

      this.videoSourcesSelect = [];
      this.audioSourcesSelect = [];
      this.currentCammera = this.useState('');
      this.selectElement =  React.createRef();

      this.lastRecordedVideo = null;
      this.canvasElement = React.createRef();
      this.canvasBlob = null;
    }

    audioLoaded = (event) => {
        this.audioElement = event.target;

        var constraints = {
            video: true
        };
        navigator.mediaDevices.getUserMedia(constraints).then((stm) => {this.videoElement.current.srcObject = stm; this.stream = stm;});
    }

    audioEnded = (event) => {
        this.stopRecorder();
        this.stopRecording();
        this.recordingStatus.value = 0;
    }

    audioOnTimeUpdate = (event) => {
        if(this.audioElement.currentTime >= this.danceCut.endTime){
            this.stopRecorder();
            this.stopRecording();
            this.recordingStatus.value = 0;
        }

        this.progressCalculation(this.audioElement.currentTime);
    }

    start = () => {
        console.log('start');
		switch(this.recordingStatus.value) {
			case 0:
				this.recordingStatus.value = 1;
                let initTime = this.danceCut.startTime - this.selectedTime.value;

				this.audioElement.currentTime = initTime >= 0 ? initTime : 0;
                
                if(initTime >= 0){
				    this.audioElement.play();
                }

                console.log(initTime);
				let currentTime = initTime;
                let countDownTime = 0;
  
				this.interval = setInterval(() => {
					if(currentTime >= this.danceCut.startTime){
						this.stopRecording(true);
						this.startRecorder();
                        this.recordingStatus.value = 2;
					}else{
                        currentTime = currentTime + 0.01;
                        countDownTime  = countDownTime + 0.01;
                        let sec = parseInt(countDownTime);
                        if(sec != this.currentSecound.value){
                            this.currentSecound.value = sec;
                        }
                    } 

                    if(this.audioElement.paused && currentTime >= 0){
                        this.audioElement.play();
                    }
				}, 10);
			break;
			case 1:
                this.recordingStatus.value = 0;
				this.stopRecording();
				this.recordingStream.stop();
			break;
			case 2:
                this.recordingStatus.value = 0;
				this.stopRecording();
				this.recordingStream.stop();
			break;
		}
	}

    stopRecorder = () => {
		this.recordingStream.ondataavailable = e => {
            this.lastRecordedVideo = e.data;
			this.videoReplyElement.current.src = URL.createObjectURL(e.data);
            this.hasRecordedVideo.value = true;
            this.getThumbNail(); 	
		};
        try{
		    this.recordingStream.stop();
        }catch (ex){

        }
        console.log('stopRecorder');
	}

    stopRecording = (skipPause) => {
		clearInterval(this.interval);
		this.currentSecound.value = 0;
		if(skipPause !== true){
			this.audioElement.pause();
		}
	}

    startRecorder = () => {
		this.recordingStream = new MediaRecorder(this.stream, {
			mimeType: 'video/webm; codecs=vp9'
		});
		this.recordingStream.start();
	}

    replyVideo = () => {
        console.log('replyVideo');
		this.showReply.value = true;
		this.videoReplyElement.current.currentTime = 0;
		this.videoReplyElement.current.play();
		
		this.audioElement.currentTime = this.danceCut.startTime;
        let delta = this.danceCut.endTime - this.danceCut.startTime
        let videoDuration = this.videoReplyElement.current.duration;
   
		this.audioElement.play();
		
		this.videoReplyElement.current.onended = () => {
			this.audioElement.pause();
			this.showReply.value = false;
		};
	}

    getDevices = () => {
        navigator.mediaDevices.enumerateDevices().then((devices) => {        
            // Iterate over all the list of devices (InputDeviceInfo and MediaDeviceInfo)
            devices.forEach((device) => {
                let option = { value: null, label: null }
                option.value = device.deviceId;

                // According to the type of media device
                switch(device.kind){
                    // Append device to list of Cameras
                    case "videoinput":
                        option.label = device.label || `Camera ${this.videoSourcesSelect.length + 1}`;
                        this.videoSourcesSelect.push(option);
                        break;
                    // Append device to list of Microphone
                    case "audioinput":
                        option.label = device.label || `Microphone ${this.audioSourcesSelect.length + 1}`;
                        this.audioSourcesSelect.push(option);
                        break;
                }
        
                console.log(device);
            });
        }).catch(function (e) {
            console.log(e.name + ": " + e.message);
        });
    }

    handleChangeCamera = (event) => {
        if (this.stream) {
            this.stream.getTracks().forEach(function (track) {
                track.stop();
            });
        }

        this.currentCammera.value = event.target.value;
        var constraints = {
            video: { 
                deviceId: { exact: event.target.value },
            }
        }
        navigator.mediaDevices.getUserMedia(constraints).then((stm) => {this.videoElement.current.srcObject = stm; this.stream = stm;});

    }

    changeCamera = () => {
        this.selectElement.current.focus();
        this.selectElement.current.click();
    }

    onSelectedTime = (time) => {
        this.selectedTime.value = time.value;
    }
    toggleTime = () => {
        this.timeSettingShow.value = !this.timeSettingShow.value;
    }

    calculateCountDown = () => {
		var c = this.selectedTime.value - this.currentSecound.value;
		return c == 0 ? 'GO' : c;
	}
    progressCalculation = (currentTime) => {
        if(currentTime < this.danceCut.startTime){
            return;
        }
        let delta = this.danceCut.endTime - this.danceCut.startTime;
        let progressDelta = currentTime - this.danceCut.startTime;

        let perc = 100 - (((delta - progressDelta)/delta) * 100)
        if (perc > 100){
            perc = 100; 
        }
        this.recordingProgress.value = perc;
    }

    componentDidMount() {
        this.loadData();
        this.getDevices();
    }

    getArea = () => {
        return (this.area) ? '/' + this.area : '';
    }

    getUrl = () => {
        return AppConfig.dataServiceUrl + this.getArea() + '/' + this.entityType + '(' + this.id + ')?$expand=Dance($expand=SongCutTemplate,DanceCut)';
    }

    getSongUrl = () => {
        return AppConfig.dataServiceUrl + '/Song/Stream/' + this.songId.value;
    }

    loadData = async () => {
        let url = this.getUrl();
        let result = await AppFetch.get(url);
        this.songId.value = result.Dance.SongCutTemplate.SongId;
        this.danceId.value = result.DanceId;
        this.fillDanceCutData(result);
    }

    fillDanceCutData = (result) => {
        let ordered = result.Dance.DanceCut.sort((a, b) => a.TimeCut-b.TimeCut);
        let prevTime = 0;
        let idx = ordered.findIndex(x => x.Id == result.Id);
        if(idx == -1) {
            return;
        }

        if(idx > 0) {
            prevTime = ordered[idx - 1].TimeCut;
            this.danceCut.startTime = prevTime;
        }

        this.danceCut.endTime = result.TimeCut;
    }

    getThumbNail = () => {
        var canvas = this.canvasElement.current;
        var video = this.videoElement.current;
        canvas.getContext('2d').drawImage(video, 0, 0, 320, 240); //video.videoWidth, video.videoHeight);
        canvas.toBlob((blob) => { this.canvasBlob = blob; },'image/png');
    }

    save = async () => {
        const formData = new FormData();
        formData.append("file", this.lastRecordedVideo);
        formData.append("filethumbnail", this.canvasBlob);
        try {
          const res = await axios.post(AppConfig.dataServiceUrl + '/File/VideoUploadFile?id=' + this.id, formData);
          if(res.status == 200){
            this.props.history.push('/dance/' + this.danceId.value + '/' + this.props.match.params.scroll+ '/' + this.props.match.params.search);
          } 
        } catch (ex) {
          console.log(ex);
        }
    }

    render() {
        return (
            <div className="user-container">
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <h1 className="display-6">Record Clip</h1>
                        { (this.danceId.value > 0) ? <Link className="btn btn-success" to={'/dance/' + this.danceId.value + '/' + this.props.match.params.scroll + '/' + (this.props.match.params.search || '') }>Back</Link> : '' }
                        { (this.songId.value > 0) ?
                        <audio className="hide-panel" onLoadedMetadata={ this.audioLoaded } onEnded={ this.audioEnded } onTimeUpdate={ this.audioOnTimeUpdate } controls>
		                    <source src={ this.getSongUrl() } type="audio/mpeg" />
		                    Your browser does not support the audio element.
	                    </audio>
                        : ''}

                        <div>
                            <video className={ (this.showReply.value ? 'hide-panel' : '') } width="100%" ref={this.videoElement} autoPlay={true}></video>
                        </div>
                        <div>
                            <video className={ (this.showReply.value ? '' : 'hide-panel') } width="100%" ref={this.videoReplyElement}></video>
                        </div>
                        <canvas className="hide-panel" width="320px" height="240px" ref={this.canvasElement}></canvas>
                        <div>
                            <div>{this.recordingProgress.value}</div>
                            <div className="record-time-progress" style={{ 'width': this.recordingProgress.value + '%' }}></div>
                        </div>
                        <div>
                            { (this.recordingStatus.value == 1) ? <div className="count-down">{ this.calculateCountDown() }</div> : '' } 
					    </div>

                        <div>
                            { (this.hasRecordedVideo.value) ? <div className="reply-button" onClick={ this.replyVideo }>
                                <FiRotateCcw size={32} color="yellow" />
                                <span className="reply-button-text">Reply</span>
                            </div> : '' }
                            
                            <div className="recorder-record-button" onClick={ this.start }>
                                <img src="/images/logo.png" alt="Avatar" />
                            </div>
                        </div>
                        <div>{ this.currentCammera.value }</div>
                        <button className="btn btn-success" onClick={ this.save }>Save</button>
                        <div><select ref={ this.selectElement } value={ this.currentCammera.value } onChange={ (event) => { this.handleChangeCamera(event); } } >{ this.videoSourcesSelect.map(x => { return  <option key={x.value} value={x.value}>{x.label}</option>  }) }</select></div>

                        { ( !this.showReply.value && this.recordingStatus.value == 0) ?  
                            <div className="switch-camera-button">
                                <FiCamera size={32} onClick= { this.changeCamera } />
                            </div> : ''}

                        <div className="record-right-section">
                            { ( !this.showReply.value && this.recordingStatus.value == 0) ?  
                            <div className="record-button-container">
                                <div className="button-shadow orange-button" onClick={ this.toggleTime }>
                                    <FiClock size={32} />
                                </div>
                                { (this.timeSettingShow.value) ? <div className="time-strip button-shadow">
                                    { this.waitSeconds.map(x => { return <div key={ x.value } className={ 'time-strip-second ' + (this.selectedTime.value == x.value ? 'selected-second' : '') } onClick={ () => this.onSelectedTime(x) }>{ x.label }</div>  }) }
                                </div> : '' }
                            </div> : ''}
                        </div>
                    </div>
                </div>
            </div>
        );
      }
}