class SHObservableUtils {
    constructor(){
    }

    isObservable = (v) => {
        return (v && v.isObservable === true);
    }
}

export default new SHObservableUtils()
