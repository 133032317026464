import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class DateCreatedLabel extends Component {
    constructor(props){
        super(props)
        this.state = {}
    }

    render() {
      return (
        <>
           <h6><small>Created { new Date(this.props.createdDate).toLocaleDateString("en-US") } by {this.props.firstName + ' ' + this.props.lastName}</small></h6>
        </>  
      );
    }
}

export default withRouter(DateCreatedLabel);