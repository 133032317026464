import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Alert from "react-bootstrap/Alert";

class FieldValidation extends Component {
    constructor(props){
        super(props)
        this.state = {}
    }

    render() {
      return (
        <>
            { this.props.validStore.length ? <div className="input-group mt-3">{ this.props.validStore.map((error) => (<Alert key={error.ErrorKey} variant="danger">{ error.Text }</Alert>))}</div> : '' }
        </>  
      );
    }
}

export default withRouter(FieldValidation);