import React, { Component } from "react";
import Menu from '../menu/menu.component';
import CardList from '../card.list/card.list.component';
import { Link } from "react-router-dom";
import AppConfig from '../../configurations/app.config';
import DateCreatedLabel from '../core/date.created.label.component';
import SHListBase from '../core/base/sh.list.base';
import AppUtils from "../../core/app.utils";

export default class VideoList extends SHListBase {
    constructor(props){
      super(props)

      this.area = 'Admin';
      this.entityType = 'Video';
    }

    getUrl = ({ startIndex, stopIndex }) => {
      return AppConfig.dataServiceUrl + '/' + this.area +'/' + this.entityType + '?$select=Path,CreatedDate,Id&$expand=CreatedBy($select=FirstName,LastName),Dance($select=Title)' + this.getSearchCriteria() + this.getSkipAndTakeUrl({ startIndex, stopIndex });
    }

    renderContent = ({style, data}) => {
      return (
        <div key={data.Id} className="card" style={style} onClick={ (id) => this.goLink(data.Id, 'video')}>
          <div className="row no-gutters">
            <div className="col-auto">
              <img src={ '/images/noperson.png' } className="img-fluid" alt="" width="64" />
            </div>
            <div className="col">
              <div className="card-block px-2">
                  <h4 className="card-title">{data.Dance.Title}</h4>
                  <DateCreatedLabel createdDate={data.CreatedDate} firstName={data.CreatedBy.FirstName} lastName={data.CreatedBy.LastName} />
              </div>
            </div>
          </div>
        </div>
      );
    }

    getSearchCriteria = () => {
      let searchText = this.searchText.value;
      if(AppUtils.isNullOrEmpty(searchText)){
        return '';
      }
      return "&$filter=contains(Dance/SongCutTemplate/Song/Title, '" + searchText + "') or contains(Dance/SongCutTemplate/Song/Author, '" + searchText + "') or contains(Dance/Title, '" + searchText + "') or contains(CreatedBy/Email, '" + searchText + "')";
    }

    render() {
      return (
        <div>
          <Menu title="Finished Videos" searchText={this.searchText} showSearch={true}  />
          { this.renderList() }
        </div>
      );
    }
}