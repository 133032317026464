import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class FieldLabel extends Component {
    constructor(props){
        super(props)
        this.state = {}
    }

    render() {
      return (
        <>
            <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">{ this.props.labelText }</span>
            </div>
        </>  
      );
    }
}

export default withRouter(FieldLabel);