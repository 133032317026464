import React from "react";
import Button from "react-bootstrap/Button";
import Draggable from 'react-draggable'; // Both at the same time
import {FiTrash2, FiChevronLeft, FiPlay, FiPause, FiScissors, FiRotateCcw, FiMinusSquare} from "react-icons/fi" 
import {BiCaretLeft} from "react-icons/bi" 

import './song.cutter.component.css';
import SHSongPlayerBase from "../core/base/sh.song.player.base";

export default class SongCutter extends SHSongPlayerBase {
    constructor(props){
        super(props)
        this.state = {}
    }

    cut = () => {
      let x = this.audioElement;
      let curPos = this.getCurrentPos(x.currentTime);

      let cut = { position: curPos, timeCut: x.currentTime, idx: this.cuts.length, id: -1 };
      this.cuts.pushInstance(cut);
      this.cuts.value.sort((a, b) => a.timeCut-b.timeCut);
      this.cuts.valueHasMutated();
    }

    deleteCut = (data) => {
      let arr = this.cuts.value;
      
      let currentIdx = arr.findIndex((p) => p == data);
      
      if(currentIdx != -1){
        this.cuts.splice(currentIdx, 1);
      }
    }

    reset = () => {
      this.props.songId.value = 11;
    }

    handleStop = (event, dragElement, x) => {
      let cut = this.cuts.value.find(p => p == x);
      
      if(cut){
        let totalSeconds = dragElement.y / this.secondHeight;
        cut.timeCut = totalSeconds;
        cut.position = dragElement.y;
      }
      this.cuts.value.sort((a, b) => a.timeCut-b.timeCut);
      this.cuts.valueHasMutated();
    }

    render() {
      return (
          <div className="song-cut-cutter-container">
            <div className="sticky-top">
            <Button className="btn btn-danger" onClick={ this.play }>{ this.isPlaying.value ? <FiPause /> : <FiPlay /> }</Button>
            <Button className="btn btn-danger" onClick={ this.reset }><FiRotateCcw /></Button>
            <Button className="btn btn-danger" onClick={ this.cut }><FiScissors /></Button>
            </div>
            <audio className="hide-panel" onLoadedMetadata={ this.audioLoaded } onTimeUpdate={ this.audioOnTimeUpdate } controls>
              <source src={ this.getSongUrl() } type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
            <div className="cutter-strip-container">
              <div className="cutter-left-strip" style={{minHeight: this.totalHeight.value}}>
              { this.cuts.value.map(x => { return (<div className="cutter-cut" key={x.position} style={{top: x.position}}><div className="cutter-cut-delete" onClick={ () => this.deleteCut(x) }><FiTrash2 size={36} /></div><div className="cutter-time">{ this.calculateDelta(x) }</div></div>) }) }
                <div className="cutter-current-position" style={{top: this.currentHeight.value}}><div className="cutter-current-time">{ this.getCurrentTime() }</div> <div className="cutter-current-arrow"><BiCaretLeft size={40} /></div></div>
              </div>
              <div className="cutter-right-strip">
              { this.cuts.value.map(x => { return (
              <Draggable
                key={x.position}
                axis="y"
                bounds="parent"
                handle=".handle"
                defaultPosition={null}
                position={{x: 0, y: x.position}}
                grid={[1, 1]}
                scale={1}
                onStop={ (event, dragElement) => this.handleStop(event, dragElement, x) }>
                <div>
                  <div className="handle-cut"><FiMinusSquare className="handle" size={40} /></div>
                  <div className="handle-line"><div className="cutter-time">{ this.calculateDelta(x) }</div></div>
                </div>
              </Draggable>
              ) }) }
              <Draggable
                axis="y"
                bounds="parent"
                handle=".handle"
                defaultPosition={null}
                position={{x: 0, y: this.currentHeight.value}}
                grid={[1, 1]}
                scale={1}
                onStop={ (event, dragElement) => this.handleStopCurrent(event, dragElement) }>
                <div  style={{top: this.currentHeight.value}}>
                  <div className="handle-cutter-current-position"><div className="cutter-current-arrow"><BiCaretLeft className="handle" size={40} /></div></div>
                </div>
              </Draggable>
              </div>
            </div>

 
        </div>
      );
    }
}