import React from "react";
import Menu from '../menu/menu.component';
import { Link } from "react-router-dom";
import SHFormBase from '../core/base/sh.form.base';
import Button from "react-bootstrap/Button";

export default class DanceCut extends SHFormBase {
    constructor(props){
        super(props)
        this.state = {}
        this.fieldDefs = {
            Status: {
                controlType: 'Combobox',
                sourceType: 'Custom',
                enum: 'DanceCutStatus',
                label: 'Status'
            },
            DanceId: {
                controlType: 'Textbox',
                showOnEdit: false
            },
        }

        this.area = 'Admin';
        this.entityType = 'DanceCut';
        this.initFields(this);
        this.initValidationState(this);
    }

    componentDidMount(...args) {
        super.componentDidMount.apply(this, args);

        this.events.postSave.add(function(model, isNew, request){
            if(request.status == null){
                model.props.history.push('/dance/' + model.data.DanceId.value + '/' + model.props.match.params.scroll+ '/' + (model.props.match.params.search || '') );
            }
        });
    }

    deleteRedirection = () => {
        this.props.history.push('/dance/' + this.data.DanceId.value + '/' + this.props.match.params.scroll+ '/' + (this.props.match.params.search || ''));
    }

    render() {
      return (
          <div>
            { this.contentDeleteDialogRender() }
            <Menu title="Approve Clip" />
            <div className="user-container">
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <Link className="btn btn-success" to={'/dance/' + this.data.DanceId.value + '/' + this.props.match.params.scroll + '/' + (this.props.match.params.search || '')}>Back</Link>
                        { this.isNew() ? "" : <Button className="btn btn-danger" onClick={ this.showDeleteDialog }>delete</Button> }

                        { this.fieldDefsArray.map(x => { return this.renderField(x) })}
                        <button className="btn btn-success" onClick={ this.save }>Save</button>
                    </div>
                </div>
            </div>
        </div>
      );
    }
}