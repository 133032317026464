import React from "react";
import AppConfig from '../../../configurations/app.config';
import AppFetch from "../../../core/app.fetch";
import AppUtils from "../../../core/app.utils";
import EntityMetadata from '../../../configurations/entity.metadata';
import ModalDialog from '../../core/modal.dialog.component';
import { toast } from 'react-toastify';
import AppEvent from "../../../core/app.event";
import SHBaseComponent from "./sh.base.component";
import FieldValidation from '../field.validation.component';
import FieldText from '../field.text.component';
import FieldLabel from '../field.label.component';
import AppEnums from "../../../configurations/app.enums";
import AppOdata from "../../../core/app.odata";
import { FiSearch } from "react-icons/fi"; 
import LightboxList from "../lightbox.list.component";

export default class SHFormBase extends SHBaseComponent {
    constructor(props){
        super(props);
        this.state = {};
        this.poco = {};
        this.entityType = '';
        this.customFieldValidation = [];
        this.validation = [];
        this.fieldDefsArray = [];
        this.dataOptions = {};
        this.area = null;
        this.id = (this.props.id) || this.useState((isNaN(this.props.match.params.id) ? (this.props.match.params.id || -1) : parseInt(this.props.match.params.id)));
        this.events = {
            postSave: new AppEvent(),
            postLoad: new AppEvent(),
            postOptionLoaded: new AppEvent() 
        }

        this.showLightboxList = this.useState(false);
        this.lightboxFieldDef = { source: function(){}, renderContent: function({style, data}){ return (<div>Default template</div> )} };
        this.expandFields = {}
    }

    initFields = (state) => {
        state.data = {};
        state.dataOptions = {};
        this.fieldDefsArray = [];
        for(let name in this.fieldDefs){
            let fielddef = this.fieldDefs[name];
            fielddef.name = name;
            fielddef.showOnEdit = fielddef.showOnEdit !== false;
            fielddef.showOnNew = fielddef.showOnNew !== false;
            switch(fielddef.controlType) {
                case 'Combobox':
                case 'LightboxList':
                    state.data[name] = this.useState('');
                    state.dataOptions[name] = this.useStateArray();
                    break;
                default:
                    state.data[name] = this.useState('');
                    break;
            }
            this.fieldDefsArray.push(fielddef);
        }
        this.fillLightboxListExpands();
    }

    getComboboxUrl = (fieldDef) => {
        return AppConfig.dataServiceUrl + this.getArea() + '/' + fieldDef.entityType
    }

    loadSingleCombobox = async (fieldDef, url) => {
        let result = await AppFetch.get(url);
        let rows = (result.value) ? result.value : result; 
        
        let comboItems = rows.map(fieldDef.sourceMap ? fieldDef.sourceMap : x => ({ value: AppUtils.getPropByPath(x, fieldDef.entityId), label: AppUtils.getPropByPath(x, fieldDef.textField), row: x }));
        
        this.fillComboboxItems(rows, fieldDef, comboItems);
    }

    fillComboboxItems = (result, fieldDef, comboItems) => {
        this.events.postOptionLoaded.execute(this, result, fieldDef, comboItems);
        
        this.dataOptions[fieldDef.name].value = [...comboItems];

        if(AppUtils.isNullOrEmpty(this.data[fieldDef.name].value) && comboItems.length > 0 && fieldDef.entityId){
            this.data[fieldDef.name].value = comboItems[0].value;
        }
    }

    fillLightboxListExpands = () => {
        let list = this.fieldDefsArray.filter(x => x.controlType == 'LightboxList');
        
        for(let li of list){
            if(this.expandFields[li.entityType] == null) {
                this.expandFields[li.entityType] = {}; 
            }

            this.expandFields[li.entityType][li.entityId] = null;
            this.expandFields[li.entityType][li.textField] = null;
        }
    }

    loadCombobox = () => {
        let combos = this.fieldDefsArray.filter(x => x.controlType == 'Combobox');

        for(let com of combos){
            switch(com.sourceType){
                case 'DataLayer':
                    let url = this.getComboboxUrl(com);
                    this.loadSingleCombobox(com, url); 
                    break;
                case 'Custom':
                    if (com.enum){
                        this.fillComboboxItems(AppEnums[com.enum], com, AppEnums[com.enum]);
                    }
                    if(com.sourceUrl){
                        let url = com.sourceUrl(this, com);
                        this.loadSingleCombobox(com, url); 
                    }
                    break;
            }
        }
    }

    handleCustomValidation = async (name, valid) => {
        for(let custom of this.customFieldValidation){
            let prop = custom.fieldName;
            if(name && name != prop){
                continue;
            }
            
            let isValid = await custom.validationFunction(this, prop);
            if(!isValid){
                valid[prop].push({ ErrorKey: custom.errorKey, Text: custom.text });
            }
        }
    }

    isNew = () => {
        return this.id.value <= 0 || (this.id.value && this.id.value.length < 5);
    }

    initValidationState = (state) => {
        state.validationField = this.useState({});
        for(let field of this.fieldDefsArray){
            state.validationField.value[field.name] = [];
        }
        let name = this.modelName || this.entityType;
        this.validation = EntityMetadata.getValidationMetadata(name);
    }

    getArea = () => {
        return (this.area) ? '/' + this.area : '';
    }

    getUrl = () => {
        let url = AppConfig.dataServiceUrl + this.getArea() + '/' + this.entityType + '(' + this.id.value + ')';
        let expand = AppOdata.recurseProperties(this.expandFields, 0);
        if(!AppUtils.isNullOrEmpty(expand)){
            url += '?$expand=' + expand;
        }

        return url;
    }

    componentDidMount() {
        if(!this.isNew()){
            this.loadData();
        }
        this.loadCombobox();
    }

    loadData = async () => { 
        let url = this.getUrl();
             
        let result = await AppFetch.get(url);
        this.poco = AppUtils.deepClone(result);
        this.events.postLoad.execute(this, result);
        this.fillData();
    }

    handleChange = (event, name) => {
        this.data[name].value = event.target.value;
    }

    showDeleteDialog = () => {
        let v = AppUtils.deepClone(this.state)
        v.showDialog = true;
        this.setState(v);
    }

    closeDeleteDialog = () => {
        let v = AppUtils.deepClone(this.state)
        v.showDialog = false;
        this.setState(v);
    }

    delete = async () => {
        let request = await AppFetch.delete(AppConfig.dataServiceUrl + this.getArea() + '/' + this.entityType + '/id:long?id=' + this.id.value);

        if(request.status){
            switch(request.status){
                case 400:
                    break;
            }
        }else{
            toast.success('Successfully deleted.');
            this.closeDeleteDialog();
            this.deleteRedirection();
        }
    }

    deleteRedirection = () => {
        let action = AppUtils.getActionFromPath(this.props.match.path);
        this.props.history.push('/' + action + 'list/' + this.props.match.params.scroll + '/' + (this.props.match.params.search || ''));
    }

    contentDeleteDialogRender = () => {
        return (<><ModalDialog ok={ this.delete } close={ this.closeDeleteDialog } show={ this.state.showDialog } title={'Delete'} message={ 'Do you want to delete a item?' } /> 
        { ( this.showLightboxList.value) ? <LightboxList fieldDef={this.lightboxFieldDef } showLightboxList={this.showLightboxList} value={ this.data[this.lightboxFieldDef.name] } options={ this.dataOptions[this.lightboxFieldDef.name] } /> : '' } </>);
    }

    save = async () => {
        let isValid = await this.handleValidation(null);
        if(!isValid){
            return;
        }
        let request;
        let delta = this.getDelta();
        let isNew = this.isNew();
        if(this.isNew()){
            request = await AppFetch.post(AppConfig.dataServiceUrl + this.getArea() + '/' + this.entityType, delta);
            if(request.Id){
                this.id.value = request.Id;
            }
        } else{
            let patch = [];
            for(let prop in delta){
                patch.push({ op: 'replace', path: prop, value: delta[prop] });
            }

            request = await AppFetch.patch(AppConfig.dataServiceUrl + this.getArea() + '/' + this.entityType + '/' + this.id.value, patch);
        }

        this.events.postSave.execute(this, isNew, request);

        if(request.status == 400){
            for(let error of request.result.errors){
                toast.warning(error.FieldName + ' - ' + error.Text);
            }
            return;
        }

        toast.success('Successfully saved.');
    }

    fillData = () => {
        for(let field of this.fieldDefsArray) {
            let initValue = this.poco[field.name];
            switch(field.controlType){
                case 'LightboxList':
                    let lentity = this.poco[field.entityType];
                    let comboItems = (Array.isArray(lentity)) ? lentity.map(field.sourceMap ? field.sourceMap : x => ({ value: AppUtils.getPropByPath(x, field.entityId), label: AppUtils.getPropByPath(x, field.textField), row: x })) : [{ value: AppUtils.getPropByPath(lentity, field.entityId), label: AppUtils.getPropByPath(lentity, field.textField), row: lentity }];
                    this.fillComboboxItems(lentity, field, comboItems)
                    break;
                default:
                    this.data[field.name].value = initValue;
                    break;
            }
        }
    }

    getDelta = () => {
        let data = this.data;
        
        let delta = {};
        
        for(let prop in data) {
            let initValue = this.poco[prop];
            let value = data[prop].value;

            if(initValue != value){
                delta[prop] = value;
            }
        }

        return delta;
    }

    handleValidation = async (name) => {
        for(let prop in this.validationField.value){
            if(name && name != prop){
                continue;
            }
            let value = this.data[prop].value;
            let validation = AppUtils.getParameterCaseInsensitive(this.validation.fields, prop);

            if(validation == null){
                continue;
            }
            
            let valid = this.validationField.value[prop];
            valid.splice(0, valid.length);
            if(validation.IsMandatory && AppUtils.isNullOrEmpty(value)){
                valid.push({ ErrorKey: 'MandatoryField', Text: 'Field is mandatory.' });  
            }

            if(validation.IsEmail && !AppUtils.isNullOrEmpty(value) && !AppUtils.isEmail(value)){
                valid.push({ ErrorKey: 'EmailField', Text: 'Field is not valid e-mail address.' });
            }

            if(!AppUtils.isNullOrEmpty(validation.Regex)){
                var re = new RegExp(validation.Regex);

                if(!re.test(value)){
                    valid.push({ ErrorKey: 'RegexField', Text: validation.RegexTranslationKey });
                };
            }
        }

        await this.handleUniquenessValidation(name, this.validationField.value);
        await this.handleCustomValidation(name, this.validationField.value);

        this.validationField.valueHasMutated();
        
        return AppUtils.isValidationValid(this.validationField.value);
    }

    handleUniquenessValidation = async (name, valid) => {
        if(this.validation.entityType && this.validation.uniqueness.length && (name == null || this.validation.uniqueness.indexOf(name) != -1)){
            let data = {};
            for(let f of this.validation.uniqueness){
                data[f] = AppUtils.getParameterCaseInsensitive(this.data, f) || null;
            }
            let primarykey = EntityMetadata.getPrimaryKey(this.entityType);
            let id = AppUtils.getParameterCaseInsensitive(this.poco, primarykey) || null;
            let request = await AppFetch.post(AppConfig.dataServiceUrl + '/application/uniqueness', { id: id, data: data, name: this.modelName || this.entityType });
            let isUnique = request.result;
            if(!isUnique){
                for(let f of this.validation.uniqueness){
                    valid[f].push({ ErrorKey: 'UniqueField', Text: 'Field must be unique.' });
                }
            } 
        }
    }

    setShowLightboxList = (field) => {
        this.lightboxFieldDef = field;
        this.showLightboxList.value = true;
    }

    renderField = (fieldDef) => {
        if((!this.isNew() && !fieldDef.showOnEdit) || (this.isNew() && !fieldDef.showOnNew)){
            return;
        }

        switch(fieldDef.controlType){
            case 'Label':
                return (
                <div key={fieldDef.name} className="input-group mb-3">
                    <FieldLabel labelText={ fieldDef.label } /><span className="form-control">{ this.data[fieldDef.name].value }</span>
                </div>);
            case 'Textbox':
                return (
                <div key={fieldDef.name} className="input-group mb-3">
                    <FieldLabel labelText={ fieldDef.label } />
                    <FieldText value={ this.data[fieldDef.name].value } onChange={ (event) => { this.handleChange(event, fieldDef.name); } } onBlur={ () => { this.handleValidation(fieldDef.name); } } name={fieldDef.name}  />
                    <FieldValidation validStore={ this.validationField.value[fieldDef.name] } />
                </div>);
            case 'LightboxList':
                return (
                <div key={fieldDef.name} className="input-group mb-3">
                    <FieldLabel labelText={ fieldDef.label } />
                    <span className="form-control">{ (this.dataOptions[fieldDef.name].value) ? this.dataOptions[fieldDef.name].value.map((option) => option.label).join(', ') : ''}</span>
                    <div className="input-group-prepend">
                        <span className="input-group-text"><FiSearch size={24} onClick={() => { this.setShowLightboxList(fieldDef) } }  /></span>
                    </div>
                    <FieldValidation validStore={ this.validationField.value[fieldDef.name] } />
                </div>);
            case 'Combobox':
                return (
                    <div key={fieldDef.name} className="input-group mb-3">
                        <FieldLabel labelText={fieldDef.label} />
                        { (this.data[fieldDef.name].value == null) ? '' :
                        <select value={this.data[fieldDef.name].value} onChange={ (event) => { this.handleChange(event, fieldDef.name); } }>
                            { this.dataOptions[fieldDef.name].value.map((option) => (
                                <option key={option.value} value={option.value}>{option.label}</option>
                            ))}
                        </select>}
                        <FieldValidation validStore={ this.validationField.value[fieldDef.name] } />
                    </div>
                );
        }
        return (<div>Missing control</div>)
    }
}