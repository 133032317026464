class AppEnums {
    constructor(){
        this.DanceCutStatus = [
            { value: 0, label: 'None' },
            { value: 1, label: 'Uploaded file' },
            { value: 2, label: 'Approved' }
        ]

        this.CommentStatus = [
            { value: 0, label: 'None' },
            { value: 1, label: 'Hide' },
        ]

        this.UserRoles = [
            { value: 0, label: 'User' },
            { value: 1, label: 'Admin' },
        ]
    }
}

export default new AppEnums();